import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="Group 986"
    xmlns="http://www.w3.org/2000/svg"
    width={1523.505}
    height={248.172}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 695"
          fill="none"
          d="M0 0h1523.505v248.172H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 986" clipPath="url(#a)">
      <circle
        data-name="Ellipse 81"
        cx={53.027}
        cy={53.027}
        r={53.027}
        transform="translate(1323.752 138.77)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4.392}
      />
      <path
        data-name="Path 2927"
        d="M1396.208 182.516a21.558 21.558 0 0 0-27.708-10.6"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4.392}
      />
      <path
        data-name="Path 2928"
        d="M1336.492 178.141a42.626 42.626 0 0 0 4.292 36.314"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={4.392}
      />
      <path
        data-name="Path 2929"
        d="M1368.343 150.107a42.582 42.582 0 0 0-28.432 20.478"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4.392}
      />
      <path
        data-name="Path 2930"
        d="M1414.286 171.735a42.52 42.52 0 0 0-37.507-22.465v10.5a32.036 32.036 0 0 0-27.955 47.666"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={4.392}
      />
      <path
        data-name="Path 2931"
        d="M1404.757 223.825a42.595 42.595 0 0 0 12.753-44.3l-10.432 1.864a32.1 32.1 0 0 0-21.837-20.492"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={4.392}
      />
      <path
        data-name="Path 2932"
        d="M1376.779 180.772a11.025 11.025 0 1 1-8.523 4.031l-8.118-6.661a21.526 21.526 0 1 0 38.168 13.656h10.5a32 32 0 0 1-15.078 27.18l4.726 9.417a42.546 42.546 0 0 1-51.578-6.36l7.258-7.591a32.077 32.077 0 0 0 32.752 7.754"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={4.392}
      />
      <path
        data-name="Line 730"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
        d="M83.337 231.966v-42.904"
      />
      <path
        data-name="Path 2933"
        d="M132.797 231.966v-26.545h-19.784v26.544"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
      />
      <path
        data-name="Line 731"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
        d="M162.472 231.966v-42.904"
      />
      <path
        data-name="Line 732"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
        d="M61.156 243.374h123.498"
      />
      <path
        data-name="Path 2934"
        d="M93.228 179.17a9.893 9.893 0 0 1-19.785 0"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
      />
      <path
        data-name="Path 2935"
        d="M113.013 179.17a9.892 9.892 0 0 1-19.784 0"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
      />
      <path
        data-name="Path 2936"
        d="M132.797 179.17a9.892 9.892 0 0 1-19.784 0"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
      />
      <path
        data-name="Path 2937"
        d="M152.58 179.17a9.892 9.892 0 0 1-19.784 0"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
      />
      <path
        data-name="Path 2938"
        d="M172.364 179.17a9.892 9.892 0 0 1-19.784 0"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
      />
      <path
        data-name="Line 733"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
        d="M93.229 165.145h79.134"
      />
      <path
        data-name="Path 2939"
        d="M73.446 179.17v-14.026l13.632-13.686h71.65l13.632 13.686v14.025"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.832}
      />
      <path
        data-name="Path 2940"
        d="M908.852 180.827V48.401a27.97 27.97 0 0 0-27.97-27.97H672.899a27.97 27.97 0 0 0-27.97 27.97v132.426l-46.764 45.439a9.446 9.446 0 0 0 6.583 16.221h344.285a9.446 9.446 0 0 0 6.587-16.221Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={12.6}
      />
      <path
        data-name="Line 734"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={12.6}
        d="M644.928 180.827h225.119"
      />
      <rect
        data-name="Rectangle 687"
        width={62.962}
        height={38.533}
        rx={6.935}
        transform="translate(236.288 201.204)"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.43}
      />
      <path
        data-name="Line 735"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2.43}
        d="M253.264 231.671h-8.911"
      />
      <path
        data-name="Line 736"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2.43}
        d="M265.904 231.671h-8.911"
      />
      <path
        data-name="Line 737"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2.43}
        d="M278.544 231.671h-8.911"
      />
      <path
        data-name="Line 738"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2.43}
        d="M291.184 231.671h-8.911"
      />
      <path
        data-name="Path 2941"
        d="M276.439 213.302a4.033 4.033 0 1 0 4.033-4.033 4.033 4.033 0 0 0-4.033 4.033Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2.43}
      />
      <path
        data-name="Path 2942"
        d="M287.151 217.334a4.033 4.033 0 0 0 0-8.066"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2.43}
      />
      <path
        data-name="Path 2943"
        d="M1203.938 207.322a19.828 19.828 0 1 1 14.02 5.808"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2944"
        d="M1203.938 207.323a19.829 19.829 0 1 1-14.021-5.808"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Line 739"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M1221.197 190.827h-12.436"
      />
      <path
        data-name="Line 740"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M1221.197 195.938h-12.436"
      />
      <path
        data-name="Path 2945"
        d="M1212.192 193.301a8.774 8.774 0 0 1 8.774-8.774h3.078"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2946"
        d="M1224.045 202.075h-3.082a8.774 8.774 0 0 1-8.774-8.774"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2947"
        d="M1196.66 225.042a3.694 3.694 0 0 1-3.693 3.693h-9.086"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2948"
        d="M1183.172 217.65a3.694 3.694 0 0 1 3.693-3.693h9.086"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2949"
        d="M1196.66 225.036a3.694 3.694 0 0 0-3.693-3.693h-6.1"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2950"
        d="M1183.172 217.651a3.694 3.694 0 0 0 3.693 3.693h6.1"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Line 741"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M1189.917 210.148v22.391"
      />
      <path
        data-name="Path 2951"
        d="M1196.619 159.235h11.779v-44.76h-11.779"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5.315}
      />
      <path
        data-name="Path 2952"
        d="M1218.043 121.645a50.165 50.165 0 0 1 26.8-3.465l6.841.993 31.465 5.528a5.766 5.766 0 0 1-1.788 11.391l-26.069-3.608"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5.315}
      />
      <path
        data-name="Path 2953"
        d="m1287.741 131.874 29.646-13.478a5.936 5.936 0 0 1 5.421 10.547l-31.368 18.079a49.911 49.911 0 0 1-20.754 6.495l-21.794 1.828a49.916 49.916 0 0 1-10.743-.26l-20.109-2.671"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={5.315}
      />
      <circle
        data-name="Ellipse 82"
        cx={10.424}
        cy={10.424}
        r={10.424}
        transform="translate(1256.425 78.937)"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={5.315}
      />
      <path
        data-name="Path 2954"
        d="M1299.951 105.263a7.922 7.922 0 1 1-7.922-7.922 7.922 7.922 0 0 1 7.922 7.922Z"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={5.315}
      />
      <path
        data-name="Path 2955"
        d="M1296.555 65.185a7.561 7.561 0 1 1-7.56-7.56 7.56 7.56 0 0 1 7.56 7.56Z"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={5.315}
      />
      <path
        data-name="Path 2956"
        d="M1143.21 168.092a56.834 56.834 0 0 0-52.747 0"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={4.752}
      />
      <path
        data-name="Path 2957"
        d="m1105.322 227.507-2.388 13.353a5.277 5.277 0 0 1-5.2 4.356h-11.962a5.277 5.277 0 0 1-5.277-5.277v-13.4a44.169 44.169 0 0 1-12.958-14.567h-11.1a5.849 5.849 0 0 1-5.849-5.849v-21.528a5.849 5.849 0 0 1 5.849-5.849h11.093l.394-.693a44.483 44.483 0 0 1 12.585-13.873l-3.066-16.917a4.093 4.093 0 0 1 2.355-4.465 4.092 4.092 0 0 1 4.818 1.117l10.518 12.639a64.013 64.013 0 0 1 11.934-2.933"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.752}
      />
      <path
        data-name="Path 2958"
        d="m1128.465 227.9 2.274 12.96a5.278 5.278 0 0 0 5.2 4.356h11.962a5.277 5.277 0 0 0 5.277-5.277v-13.4c10.588-7.755 17.218-18.853 17.218-31.181 0-18.944-15.661-34.991-37.283-40.45"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.752}
      />
      <path
        data-name="Path 2959"
        d="M1103.705 236.516a67.738 67.738 0 0 0 26.264 0"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.752}
      />
      <path
        data-name="Line 742"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.752}
        d="m1159.243 169.44 8.748-8.748"
      />
      <path
        data-name="Path 2960"
        d="M1105.487 162.702a14.605 14.605 0 0 1-.165-2.189 14.458 14.458 0 1 1 28.463 3.6"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={4.752}
      />
      <path
        data-name="Path 2961"
        d="M1078.826 184.75a5.818 5.818 0 1 1 11.637 0"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={4.752}
      />
      <path
        data-name="Path 2962"
        d="m976.076 189.494 4.539 27.758a8.744 8.744 0 0 0 8.628 7.332h41.771a8.742 8.742 0 0 0 8.628-7.332l4.908-30.013a5.153 5.153 0 0 0-5.086-5.986h-70.442a5.3 5.3 0 0 1-5.231-4.446l-1.6-9.8"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3.3}
      />
      <path
        data-name="Path 2963"
        d="M972.734 239.599a5.477 5.477 0 1 0 5.476-5.476 5.476 5.476 0 0 0-5.476 5.476Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3.3}
      />
      <path
        data-name="Path 2964"
        d="M1025.458 239.599a5.477 5.477 0 1 0 5.476-5.476 5.476 5.476 0 0 0-5.476 5.476Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3.3}
      />
      <path
        data-name="Line 743"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
        d="M1029.421 213.854H990.8"
      />
      <path
        data-name="Line 744"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
        d="M989.384 204.61h41.452"
      />
      <path
        data-name="Line 745"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M849.278 142.078H704.501"
      />
      <path
        data-name="Line 746"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M817.823 77.214h-81.867"
      />
      <path
        data-name="Line 747"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={6}
        d="M690.747 158.209h172.286"
      />
      <path
        data-name="Path 2965"
        d="M784.668 127.739v-27.455a7.778 7.778 0 1 0-15.557 0v27.452"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={6}
      />
      <path
        data-name="Path 2966"
        d="M751.513 127.739v-27.455a7.778 7.778 0 1 0-15.557 0v27.452"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={6}
      />
      <path
        data-name="Path 2967"
        d="M817.823 127.739v-27.455a7.778 7.778 0 1 0-15.557 0v27.452"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={6}
      />
      <path
        data-name="Path 2968"
        d="m705.061 77.21 65.556-41.125a11.8 11.8 0 0 1 12.545 0l65.555 41.125"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={6}
      />
      <path
        data-name="Path 2969"
        d="M1150.223 67.768a48.39 48.39 0 1 1-48.39-48.39"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={6.221}
      />
      <path
        data-name="Path 2970"
        d="M1117.962 67.768c0 26.726-7.222 48.39-16.13 48.39s-16.13-21.665-16.13-48.39 7.222-48.39 16.13-48.39"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={6.221}
      />
      <path
        data-name="Path 2971"
        d="M1150.223 67.765c0 11.135-21.665 20.163-48.39 20.163s-48.39-9.027-48.39-20.163 21.665-20.163 48.39-20.163"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={6.221}
      />
      <path
        data-name="Path 2972"
        d="m1140.671 36.213 6.5 21.668 5.09-2.951.437-25.692 16.007-9.279a6.452 6.452 0 0 0-6.472-11.164l-16.001 9.281-22.513-12.389-5.09 2.95 15.571 16.412-12.087 7.007-9.437-.614-6.071 3.518 13.831 5.527-2.077 14.748 6.07-3.519 4.156-8.494Z"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={6.221}
      />
      <path
        data-name="Path 2973"
        d="M1330.112 227.488h-77.937a8.058 8.058 0 0 1-8.058-8.058v-41.446a8.058 8.058 0 0 1 8.058-8.058h77.937a8.058 8.058 0 0 1 8.057 8.058v41.445a8.058 8.058 0 0 1-8.057 8.058"
        fill="#2726da"
      />
      <rect
        data-name="Rectangle 688"
        width={94.053}
        height={57.562}
        rx={10.359}
        transform="translate(1244.117 169.926)"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
      />
      <path
        data-name="Line 748"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
        d="M1321.087 242.281H1261.2"
      />
      <path
        data-name="Line 749"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
        d="m1280.83 242.281 6.796-14.793"
      />
      <path
        data-name="Line 750"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
        d="m1301.457 242.281-6.796-14.793"
      />
      <path
        data-name="Path 2974"
        d="M1277.153 181.727c-11.036 0-19.985 6.26-19.985 13.981 0 3.5 1.84 6.691 4.873 9.142l-3.808 10.835h1.88l8.081-7.482a27.076 27.076 0 0 0 8.959 1.485c11.036 0 19.985-6.26 19.985-13.981s-8.949-13.98-19.985-13.98Z"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
      />
      <path
        data-name="Path 2975"
        d="M1298.629 204.352a21.152 21.152 0 0 0 10.077 2.432 22.24 22.24 0 0 0 7.358-1.22l6.636 6.144h1.544l-3.128-8.9a9.749 9.749 0 0 0 4-7.509c0-6.341-7.349-11.482-16.412-11.482a21.16 21.16 0 0 0-10.077 2.432"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
      />
      <path
        data-name="Path 2976"
        d="m525.598 92.594 19.319 19.321-19.321 19.321"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={10.357}
      />
      <circle
        data-name="Ellipse 83"
        cx={80.563}
        cy={80.563}
        r={80.563}
        transform="translate(320.577 58.206)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={10.357}
      />
      <path
        data-name="Path 2977"
        d="M434.708 138.769c0 44.494-15.029 80.563-33.568 80.563s-33.569-36.07-33.569-80.563 15.03-80.563 33.57-80.563 33.567 36.073 33.567 80.563Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={10.357}
      />
      <path
        data-name="Path 2978"
        d="M320.577 138.77c0-14.831 36.069-26.855 80.563-26.855h143.78"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={10.357}
      />
      <path
        data-name="Path 2979"
        d="M481.705 138.775c0 14.831-36.07 26.855-80.563 26.855H256.636"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={10.357}
      />
      <path
        data-name="Path 2980"
        d="m275.957 184.945-19.321-19.321 19.321-19.321"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={10.357}
      />
      <path
        data-name="Path 2981"
        d="M894.078 142.329V87.322a15.983 15.983 0 0 1 15.983-15.983h111.548a15.983 15.983 0 0 1 15.983 15.983v55.007a15.983 15.983 0 0 1-15.983 15.983H910.061a15.983 15.983 0 0 1-15.983-15.983"
        fill="#2726da"
      />
      <rect
        data-name="Rectangle 689"
        width={143.514}
        height={86.972}
        rx={20.547}
        transform="translate(894.078 71.339)"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={7.2}
      />
      <path
        data-name="Line 751"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={7.2}
        d="M912.182 123.564v-17.478"
      />
      <path
        data-name="Rectangle 690"
        fill="#2726da"
        d="M1121.12 97.878h77.541v38.011h-77.541z"
      />
      <path
        data-name="Rectangle 691"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
        d="M1121.12 97.878h77.541v38.011h-77.541z"
      />
      <path
        data-name="Path 2982"
        d="M1177.314 113.71a3.174 3.174 0 0 1 3.175-3.175h6.92"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
      />
      <path
        data-name="Path 2983"
        d="M1187.589 120.059a3.174 3.174 0 0 1-3.175 3.175h-6.92"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
      />
      <path
        data-name="Path 2984"
        d="M1177.314 113.71a3.174 3.174 0 0 0 3.175 3.174h3.927"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
      />
      <path
        data-name="Path 2985"
        d="M1187.588 120.059a3.174 3.174 0 0 0-3.175-3.175h-3.926"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
      />
      <path
        data-name="Line 752"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
        d="M1182.451 126.817V106.95"
      />
      <path
        data-name="Line 753"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
        d="M1167.498 112.905h-35.99"
      />
      <path
        data-name="Line 754"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.3}
        d="M1167.498 120.863h-35.99"
      />
      <path
        data-name="Path 2986"
        d="M663.939 125.012a44.373 44.373 0 1 1-44.373-44.373 44.373 44.373 0 0 1 44.373 44.373"
        fill="#2726da"
      />
      <circle
        data-name="Ellipse 84"
        cx={44.373}
        cy={44.373}
        r={44.373}
        transform="translate(575.194 80.639)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2987"
        d="M642.76 148.814v-11.371a8.724 8.724 0 0 0-8.724-8.724h-9.959l-1.758 2.351a4.954 4.954 0 0 1-7.935 0l-1.758-2.351h-9.959a8.724 8.724 0 0 0-8.724 8.724v11.366"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Line 755"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M634.139 141.813h-7.171"
      />
      <path
        data-name="Path 2988"
        d="M614.08 95.738a12.672 12.672 0 0 0 15.9 8.228c.152-.047.293-.112.442-.165"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2989"
        d="M630.432 103.823a12.674 12.674 0 1 1-15.911-8.25 12.674 12.674 0 0 1 15.911 8.25Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 2990"
        d="M567.775 244.438H522a13.3 13.3 0 0 1-13.3-13.3v-92.826a13.3 13.3 0 0 1 13.3-13.3h45.774a13.3 13.3 0 0 1 13.3 13.3v92.825a13.3 13.3 0 0 1-13.3 13.3"
        fill="#2726da"
      />
      <path
        data-name="Rectangle 692"
        d="M525.8 125.011h38.177a17.1 17.1 0 0 1 17.1 17.1v85.23a17.1 17.1 0 0 1-17.1 17.1H525.8a17.1 17.1 0 0 1-17.1-17.102v-85.228a17.1 17.1 0 0 1 17.1-17.1Z"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={5.992}
      />
      <path
        data-name="Path 2991"
        d="M564.143 127.365v32.4a55.44 55.44 0 0 1-55.439 55.439"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={5.992}
      />
      <path
        data-name="Line 756"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={5.992}
        d="M552.16 229.373h-14.545"
      />
      <path
        data-name="Path 2992"
        d="M249.573 214.392h-52.169a5.4 5.4 0 0 1-5.395-5.395v-27.743a5.4 5.4 0 0 1 5.395-5.395h52.169a5.4 5.4 0 0 1 5.395 5.395v27.744a5.4 5.4 0 0 1-5.395 5.395"
        fill="#2726da"
      />
      <rect
        data-name="Rectangle 693"
        width={62.962}
        height={38.533}
        rx={6.935}
        transform="translate(192.008 175.859)"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={2.43}
      />
      <path
        data-name="Path 2993"
        d="M246.904 197.416v8.912h-8.911"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.43}
      />
      <path
        data-name="Path 2994"
        d="M238.939 202.817v-4.458h4.455"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.43}
      />
      <path
        data-name="Line 757"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={2.43}
        d="M192.009 184.778h62.961"
      />
      <path
        data-name="Line 758"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={2.43}
        d="M192.009 191.137h62.961"
      />
      <rect
        data-name="Rectangle 694"
        width={92.181}
        height={56.416}
        rx={10.153}
        transform="translate(201.139 72.295)"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.558}
      />
      <path
        data-name="Line 759"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.558}
        d="M225.993 116.903h-13.046"
      />
      <path
        data-name="Line 760"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.558}
        d="M244.5 116.903h-13.046"
      />
      <path
        data-name="Line 761"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.558}
        d="M263.006 116.903H249.96"
      />
      <path
        data-name="Line 762"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.558}
        d="M281.512 116.903h-13.046"
      />
      <path
        data-name="Path 2995"
        d="M259.925 90.008a5.9 5.9 0 1 0 5.9-5.9 5.9 5.9 0 0 0-5.9 5.9Z"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.558}
      />
      <path
        data-name="Path 2996"
        d="M275.607 95.913a5.904 5.904 0 0 0 0-11.809"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.558}
      />
      <path
        data-name="Path 2997"
        d="M187.406 89.86a28.16 28.16 0 1 1-28.161 28.161 28.161 28.161 0 0 1 28.161-28.161"
        fill="#2726da"
      />
      <path
        data-name="Path 2998"
        d="M187.406 89.86a28.16 28.16 0 1 1-28.161 28.161 28.161 28.161 0 0 1 28.161-28.161Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3.993}
      />
      <path
        data-name="Path 2999"
        d="M170.04 118.02a17.366 17.366 0 0 1 17.366-17.361"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.993}
      />
      <path
        data-name="Path 3000"
        d="m210.898 133.442 25.175 25.175-8.074 8.073-25.175-25.175"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3.993}
      />
      <path
        data-name="Line 763"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.7}
        d="M55.505 212.977H44.312"
      />
      <path
        data-name="Line 764"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.7}
        d="M55.505 217.577H44.312"
      />
      <path
        data-name="Path 3001"
        d="M47.4 215.204a7.9 7.9 0 0 1 7.9-7.9h2.765"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.7}
      />
      <path
        data-name="Path 3002"
        d="M58.065 223.101h-2.768a7.9 7.9 0 0 1-7.9-7.9"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.7}
      />
      <path
        data-name="Path 3003"
        d="M70.58 215.205a17.846 17.846 0 1 1-17.842-17.846 17.846 17.846 0 0 1 17.842 17.846Z"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={2.7}
      />
      <path
        data-name="Line 765"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={7.251}
        d="m514.46 46.037-40.395 36.602"
      />
      <path
        data-name="Line 766"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={7.251}
        d="m579.643 54.578-40.612-12.383"
      />
      <path
        data-name="Path 3004"
        d="M514.459 39.709a12.033 12.033 0 1 0 12.034-12.033 12.034 12.034 0 0 0-12.034 12.033Z"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={7.251}
      />
      <path
        data-name="Path 3005"
        d="M579.643 54.579a12.033 12.033 0 1 0 12.034-12.034 12.034 12.034 0 0 0-12.034 12.034Z"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={7.251}
      />
      <path
        data-name="Path 3006"
        d="M621.615 4.182h19.13v19.13"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={7.251}
      />
      <path
        data-name="Line 767"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={7.251}
        d="m599.471 45.419 41.237-41.237"
      />
      <path
        data-name="Path 3007"
        d="M514.233 221.347a23.993 23.993 0 1 1-23.993-24 23.993 23.993 0 0 1 23.993 24"
        fill="#2726da"
      />
      <circle
        data-name="Ellipse 85"
        cx={23.993}
        cy={23.993}
        r={23.993}
        transform="rotate(-1.363 8552.696 -19475.806)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3.63}
      />
      <path
        data-name="Path 3008"
        d="M497.972 225.813a4.469 4.469 0 0 1-4.469 4.469h-10.994"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
      />
      <path
        data-name="Path 3009"
        d="M481.651 216.875a4.469 4.469 0 0 1 4.469-4.469h10.994"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
      />
      <path
        data-name="Path 3010"
        d="M497.971 225.813a4.469 4.469 0 0 0-4.469-4.469h-7.382"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
      />
      <path
        data-name="Path 3011"
        d="M481.651 216.872a4.469 4.469 0 0 0 4.469 4.469h7.382"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
      />
      <path
        data-name="Line 768"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.63}
        d="M489.811 207.798v27.094"
      />
      <path
        data-name="Path 3012"
        d="M1469.947 202.074v-2.879a2.711 2.711 0 0 0-3.127-2.678l-27.448 4.274"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={2.121}
      />
      <path
        data-name="Path 3013"
        d="m1477.318 231.632-16.95-2.994a21 21 0 0 1-17.348-20.68"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.121}
      />
      <path
        data-name="Path 3014"
        d="M1479.745 207.957v-6.09l-29.839.625"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={2.121}
      />
      <path
        data-name="Path 3015"
        d="m1480.46 238.767-40.36-7.129a3.606 3.606 0 0 1-2.979-3.551v-24.436a2.867 2.867 0 0 1 3.365-2.822l40.36 7.129a3.6 3.6 0 0 1 2.979 3.55v24.441a2.866 2.866 0 0 1-3.365 2.818Z"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={2.121}
      />
      <path
        data-name="Path 3016"
        d="M958.057 110.6h32.927v8.893l13.746-13.746-13.746-13.746v8.879h-23.207a9.719 9.719 0 0 0-9.72 9.72Z"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Path 3017"
        d="M969.595 127.657h-23.207v8.88l-13.746-13.746 13.746-13.746v8.893h32.927a9.719 9.719 0 0 1-9.72 9.719Z"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3}
      />
      <path
        data-name="Line 769"
        fill="none"
        stroke="#fff"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M0 247.005h1523.505"
      />
      <path
        data-name="Path 3018"
        d="m434.175 160.912 15.4 30.8c5.6 11.208-1.921 24.837-14.437 25.472q-.48.025-.965.024t-.966-.024c-12.514-.635-20.04-14.263-14.436-25.472Z"
        fill="#2726da"
      />
      <path
        data-name="Path 3019"
        d="m434.175 160.912 15.4 30.8c5.6 11.208-1.921 24.837-14.437 25.472q-.48.025-.965.024t-.966-.024c-12.514-.635-20.04-14.263-14.436-25.472Z"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Path 3020"
        d="m400.206 128.747 15.4 30.8c5.6 11.208-1.921 24.837-14.436 25.472q-.481.025-.966.024t-.965-.024c-12.515-.635-20.04-14.263-14.437-25.472Z"
        fill="#2726da"
      />
      <path
        data-name="Path 3021"
        d="m400.206 128.747 15.4 30.8c5.6 11.208-1.921 24.837-14.436 25.472q-.481.025-.966.024t-.965-.024c-12.515-.635-20.04-14.263-14.437-25.472Z"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Line 770"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
        d="M434.174 200.217v9.055"
      />
      <path
        data-name="Path 3022"
        d="m440.729 191.644-6.554 8.573-6.555-8.573"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Line 771"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
        d="M439.768 200.217H428.58"
      />
      <path
        data-name="Line 772"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
        d="M439.768 204.891H428.58"
      />
      <path
        data-name="Path 3023"
        d="m373.383 177.632 15.4 30.8c5.6 11.208-1.921 24.837-14.436 25.472q-.481.024-.966.024t-.966-.024c-12.515-.635-20.04-14.263-14.436-25.472Z"
        fill="#2726da"
      />
      <path
        data-name="Path 3024"
        d="m373.383 177.632 15.4 30.8c5.6 11.208-1.921 24.837-14.436 25.472q-.481.024-.966.024t-.966-.024c-12.515-.635-20.04-14.263-14.436-25.472Z"
        fill="none"
        stroke="#2680da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Path 3025"
        d="M394.184 162.429a3.721 3.721 0 0 1 3.721-3.721h7.608"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Path 3026"
        d="M406.226 169.87a3.721 3.721 0 0 1-3.721 3.721h-7.608"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Path 3027"
        d="M394.184 162.429a3.721 3.721 0 0 0 3.721 3.721h4.6"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Path 3028"
        d="M406.227 169.871a3.721 3.721 0 0 0-3.721-3.721h-4.6"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Line 773"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
        d="M400.205 176.788V155.51"
      />
      <path
        data-name="Line 774"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
        d="M375.294 212.714h-11.66"
      />
      <path
        data-name="Line 775"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
        d="M375.294 217.506h-11.66"
      />
      <path
        data-name="Path 3029"
        d="M366.847 215.035a8.227 8.227 0 0 1 8.227-8.227h2.887"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
      <path
        data-name="Path 3030"
        d="M377.965 223.262h-2.887a8.227 8.227 0 0 1-8.227-8.227"
        fill="none"
        stroke="#26d7da"
        strokeMiterlimit={10}
        strokeWidth={3.321}
      />
    </g>
  </svg>
)

export default SvgComponent
