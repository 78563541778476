import React, { useState, useEffect } from 'react';
import {
  FullSizeContainer,
  Logo,
  AbsoluteFillContainer,
  ResultComps,
  getCountryThumb,
  Video,
} from 'components';
import { CardSchemeChart } from 'components/results';
import classes from './Results.module.scss';
import utilClasses from '../Utils.module.scss';
import { COLOR, ASSETS, SVG, HOST } from 'const';
import { IReportData, IReportDataSingleCountry, IAllData } from 'type';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import cs from 'classnames';

import './Results.all.css';

const SelectReport = () => {
  const [isForPrint] = useState(window.location.pathname.includes('/report'));
  const [data, setData] = useState<IAllData>();
  const [countryData, setCountryData] = useState<IReportDataSingleCountry>();
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('viewReport_name'));
  useEffect(() => {
    if (!isForPrint) {
      const savedData = localStorage.getItem('data');
      try {
        if (!savedData) return;
        const parsedSaveData: IAllData = JSON.parse(savedData)
        setData(parsedSaveData);
        const countries = Object.keys(parsedSaveData.reportData).map(val => ({ label: val, value: val }));
        setCountryData(parsedSaveData.reportData[selectedCountry || countries[0].value]);
      } catch (err) { }
    } else {
      const reportId = (() => {
        const parts = window.location.pathname.split('/');
        return parts[parts.length - 1];
      })();
      console.log('reportId', reportId);
      fetch(`${HOST}/report/` + reportId).then(res => res.json())
        .then(json => {
          if (Object.keys(json).length === 0) return;
          setSelectedCountry(json.data.name);
          setCountryData(json.data.data);
        })
    }
  }, []);

  const percentage = !countryData ? 0 : +(countryData?.overview?.percentage * 100).toFixed(2);

  const listTopUnsupportedAPMs = !countryData ? [] : countryData.APMs?.topUnsupported.map(val => {
    return {
      ...val,
      percentage: `${(val.percentage * 100).toFixed(2)} %`,
      value: val.revenue,
    }
  })

  const additionalUnsupportedAPMs = !countryData ? [] : countryData.APMs.additional.map(val => {
    return {
      name: val.name,
      value: `${(val.percentage * 100).toFixed(2)} %`,
    };
  });

  const cardSchemeData = !countryData ? { support: [], unsupport: [] } : {
    support: [
      { name: 'credit card', value: `${(countryData.cardSchemes.creditCardPercentage * 100).toFixed(0)} %` },
      { name: 'debit card', value: `${(countryData.cardSchemes.debitCardPercentage * 100).toFixed(0)} %` },
    ],
    unsupport: countryData.cardSchemes.topUnsupportedCardSchemes.map(val => ({
      name: val.name,
      value: `${(val.percentage * 100).toFixed(2)} %`,
    }))
  };

  const renderBottom = (scrollPosition: number, decorateColor: string, darkArrow = false, style = {}) => {
    return (
      <>
        {!isForPrint &&
          <div className={classes.start} style={style}>
            <SVG.HomeBannerArrowAnim
              onClick={() => window.scrollTo({ top: scrollPosition, left: 0, behavior: 'smooth' })}
              color={darkArrow ? COLOR.MAIN : COLOR.WHITE}
            />
          </div>}
        {!!decorateColor && (
          <div className={classes.decorateBottom} style={{ backgroundColor: decorateColor }} />
        )}
      </>
    )
  };
  console.log('countryData', countryData);
  if (!countryData) {
    return (
      <FullSizeContainer bgColor={COLOR.DARK}>
        <AbsoluteFillContainer middle>
          <h3 className={classes.heading}>Loading...</h3>
        </AbsoluteFillContainer>
      </FullSizeContainer>
    )
  };

  if (!selectedCountry) {
    return (
      <FullSizeContainer bgColor={COLOR.DARK}>
        <AbsoluteFillContainer middle>
          <h3 className={classes.heading}>Something's wrong, please try again...</h3>
        </AbsoluteFillContainer>
      </FullSizeContainer>
    )
  };

  return (
    <>
      <FullSizeContainer bgColor={'#122B4E'}>
        <AbsoluteFillContainer middle style={{ flexDirection: 'row' }}>
          <div className={classes.countryImage}>
            <img src={getCountryThumb(selectedCountry, 'b')} alt={selectedCountry} />
            <div />
          </div>
          <div className={classes.headingSection}>
            <p style={{ marginBottom: 0 }}>Here’s your report for</p>
            <h3 className={classes.heading} style={{ marginTop: 0 }}>{selectedCountry}</h3>
          </div>
          <div className={classes.headerArtworkSection}>
            {!isForPrint ? (
              <Video
                controls={false}
                muted
                autoPlay
                loop
                playsInline
                srcs={[
                  { src: ASSETS.ALPHA_MOV_VIDEO_OVERVIEW_GRAPHIC, type: 'video/quicktime', device: 'safari' },
                  { src: ASSETS.ALPHA_WEBM_VIDEO_OVERVIEW_GRAPHIC, type: 'video/webm', device: 'chrome, firefox' },
                ]}
              />
            ) : null}
          </div>
        </AbsoluteFillContainer>
        {renderBottom(window.innerHeight, '#122B4E')}
        <Logo bottom />

      </FullSizeContainer>
      {!isForPrint &&
        <div className={classes.downloadContainer}>
          <a href="/download"><button className={classes.downloadCopy}>Download copy</button></a>
          <a href="/select-report"><button className={classes.back}>Back to countries</button></a>
        </div>}
      <FullSizeContainer bgColor={'#2F2BE0'}>
        {isForPrint && <div style={{ height: 60 }} />}
        <div className={classes.overviewRow1}>
          <div style={{ width: '40%' }}>
            <h3 className={classes.heading} style={{ marginBottom: 10 }}>Overview</h3>
            <p style={{ color: 'white' }}>
              The online payment landscape is rapidly evolving. More and more, your customers and prospects are adopting alternative payment methods (APMs) when making purchases online. This report highlights the potential uplift your business could achieve if you support the most relevant local payment methods in your target countries.
            </p>
          </div>
          {isForPrint ? (
            <img src={ASSETS.RESULTS_OVERVIEW_ARTWORK} />
          ) : (
            <Video
              controls={false}
              muted
              autoPlay
              loop
              playsInline
              srcs={[
                { src: ASSETS.ALPHA_MOV_VIDEO_BAG, type: 'video/quicktime', device: 'safari' },
                { src: ASSETS.ALPHA_WEBM_VIDEO_BAG, type: 'video/webm', device: 'chrome, firefox' },
              ]}
            />
          )}
        </div>

        <div className={classes.overviewRow2}>
          <div>
            <div style={{ width: 200 }}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage} %`}
                styles={buildStyles({
                  strokeLinecap: 'round',
                  textSize: '16px',
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(38, 215, 218)`,
                  textColor: '#fff',
                  // trailColor: '#575A65',
                  trailColor: 'rgba(255,255,255,0.05)',
                  backgroundColor: 'transparent',
                })}

              />
            </div>
            <p>You’re supporting {percentage}% of potential eCommerce transactions with your currently supported payment methods and card schemes.</p>
          </div>
          <div>
            <div className={classes.infoBox}>
              <div>
                <p style={{ textAlign: 'left', paddingLeft: 5, paddingRight: 5 }}>Potential revenue uplift by supporting all available APMs:</p>
              </div>
              <div>
                <span>+{countryData.overview.revenue}</span>
              </div>
            </div>
          </div>
        </div>
        {renderBottom(window.innerHeight * 2, '#2F2BE0')}
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.WHITE}>
        {isForPrint && <div style={{ height: 60 }} />}
        <div className={classes.APMRow1}>
          <div style={{ width: '40%' }}>
            <h3 className={classes.heading} style={{ marginBottom: 10 }}>Alternative Payments</h3>
            <p style={{ color: '#575A65' }}>
              Providing a series of alternative payment methods (APMs) provides simplicity, efficiency and clarity for your customers across the world. As you can see below, preferences and options vary considerably by country, and the cost of not supporting the correct payments methods can be high.
            </p>
          </div>
          {/* <img src={ASSETS.RESULTS_APMS_ARTWORK} /> */}
          {isForPrint ? (
            <img src={ASSETS.RESULTS_APMS_ARTWORK} />
          ) : (
            <Video
              controls={false}
              muted
              autoPlay
              loop
              playsInline
              srcs={[
                { src: ASSETS.ALPHA_MOV_VIDEO_CARDS, type: 'video/quicktime', device: 'safari' },
                { src: ASSETS.ALPHA_WEBM_VIDEO_CARDS, type: 'video/webm', device: 'chrome, firefox' },
              ]}
            />
          )}

        </div>
        <p className={cs(classes.APMRow2, classes.APMText)}>Your top unsupported APMs:</p>
        <div className={classes.APMRow3}>
          {listTopUnsupportedAPMs.map((val, i) => (
            <ResultComps.UnsupportedAPMColumn
              key={i}
              name={val.name}
              rank={val.rank}
              percentage={val.percentage}
              value={val.value}
            />
          ))}
        </div>
        <div className={classes.APMRow4}>
          {/* <div className={classes.infoBox}>
            <div>
              <p>Potential revenue uplift by supporting all available APMs:</p>
            </div>
            <div>
              <span>+{countryData.overview.revenue}</span>
            </div>
          </div> */}

          <div className={classes.APMAdditionInfo}>
            <p>Additional unsupported APMs include:</p>
            <div>
              {additionalUnsupportedAPMs.map((val, i) => (
                <div className={classes.additionalContainer} key={'additional-' + i}>
                  <span className={classes.additionalName}>{val.name}</span>
                  <span className={classes.additionalValue}>{val.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {renderBottom(window.innerHeight * 3, COLOR.WHITE, true)}
      </FullSizeContainer>

      <FullSizeContainer bgColor={'#2F2BE0'}>
        {isForPrint && <div style={{ height: 60 }} />}
        <div className={classes.overviewRow1}>
          <div style={{ width: '40%' }}>
            <h3 className={classes.heading} style={{ marginBottom: 10 }}>Card Schemes</h3>
            <p style={{ color: 'white' }}>
              How many of your customers want to pay using a credit or debit card scheme you don’t currently support? Take a look below.
            </p>
          </div>
          {/* <img src={ASSETS.RESULTS_CARD_SCHEME_ARTWORK} /> */}
          {isForPrint ? (
            <img src={ASSETS.RESULTS_CARD_SCHEME_ARTWORK} />
          ) : (
            <Video
              controls={false}
              muted
              autoPlay
              loop
              playsInline
              srcs={[
                { src: ASSETS.ALPHA_MOV_VIDEO_GRAPH, type: 'video/quicktime', device: 'safari' },
                { src: ASSETS.ALPHA_WEBM_VIDEO_GRAPH, type: 'video/webm', device: 'chrome, firefox' },
              ]}
            />
          )}
        </div>
        <div className={classes.cardSchemeRow2}>

          <CardSchemeChart
            creditValue={(countryData?.cardSchemes.creditCardPercentage || 0) * 100}
            debitValue={(countryData?.cardSchemes.debitCardPercentage || 0) * 100}
          />

          <div className={classes.cardSchemeRow2Info}>
            <p>You’re currently supporting:</p>
            {isForPrint && <div style={{ height: 60 }} />}
            <div>
              {cardSchemeData.support.map((val, i) => (
                <div key={i} className={classes.item}><span>{val.value}</span> of potential {val.name} eCommerce transactions</div>
              ))}
            </div>

            <div className={classes.unsupport}>
              <p>Your top unsupported card schemes:</p>
              <div>
                <img src={ASSETS.RESULTS_CARD_LINE} />
                <div>
                  {cardSchemeData.unsupport.map((val, i) => (
                    <div className={classes.unsupportCard} key={'unsupportCard-' + i}>
                      <span className={classes.unsupportCardName}>{val.name}</span>
                      <span className={classes.unsupportCardValue}>{val.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

          </div>
        </div>
        {renderBottom(window.innerHeight * 4, '#2F2BE0')}
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.WHITE}>
        <AbsoluteFillContainer middle>
          {isForPrint && <div style={{ height: 60 }} />}
          <div style={{ width: '100%', transform: isForPrint ? 'translateY(-40px)' : undefined }}>
            <h3 className={classes.heading} style={{ color: COLOR.MAIN, textAlign: 'left', marginBottom: 20 }}>{selectedCountry} Commerce Snapshot</h3>
          </div>
          <div style={{ height: isForPrint ? 470 : undefined }}>
            <ResultComps.CountrySnapshot
              total={countryData.countrySnapshot.total}
              country={selectedCountry || ''}
              mostPopular={countryData.countrySnapshot.mostPopular}
              percentage={+(countryData.countrySnapshot.percentage * 100).toFixed(2)}
            />
          </div>
          {renderBottom(window.innerHeight * 5, COLOR.WHITE, true, { position: 'relative', width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 })}
        </AbsoluteFillContainer>
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.MAIN}>
        {isForPrint && <div style={{ height: 60 }} />}
        <div className={classes.commerceGrowRow1}>
          <h3 className={classes.heading} style={{ marginBottom: 10 }}>Commerce Growth 2018-2022</h3>
          <p style={{ color: 'white', width: '40%' }}>
            eCommerce in {selectedCountry} is set to increase by {Math.floor(100 * countryData.commerceGrowthPercentage.eCommerce)}% between 2018 and 2022, while mCommerce in the same period of time is expected to grow by {Math.floor(100 * countryData.commerceGrowthPercentage.mCommerce)}%.
          </p>
        </div>
        {/* <img src={ASSETS.RESULTS_CHART_DEMO} className={classes.chartDemo} alt="" /> */}
        <ResultComps.CommerceGrowthChart
          data={countryData.commerceGrowth}
          growthPercentage={countryData.commerceGrowthPercentage}
        />
        {renderBottom(window.innerHeight * 6, COLOR.MAIN, false, { position: 'relative', width: '100%', display: 'flex', alignItems: 'center', marginTop: 5 })}
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.WHITE}>
        {isForPrint && <div style={{ height: 60 }} />}
        <div className={classes.instoreRow1}>
          <h3 className={classes.heading}>In Store</h3>
          <p>Percentage share of payment methods in store for 2020</p>
          {/* <img src={ASSETS.RESULTS_CHART_DEMO_2} className={classes.chartDemo2} alt="" /> */}
          <ResultComps.InStoreChart
            data={countryData.inStore}
          />
        </div>
        {!isForPrint && renderBottom(window.innerHeight * 7, COLOR.WHITE, true, { position: 'relative', width: '100%', display: 'flex', alignItems: 'center', marginTop: 10 })}
      </FullSizeContainer>

      {!isForPrint &&
        <FullSizeContainer bgColor={COLOR.MAIN}>
          <div className={classes.nextStep1}>
            <h3 className={classes.heading}>Unlock your revenue <br />
              with ACI Worldwide</h3>
          </div>
          <div className={classes.nextStep2}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={classes.nextStep2Left}>
                <p>As this snapshot of {selectedCountry} shows, you’re only supporting 37.97% of your potential eCommerce transactions. ACI allows you to easily accept hundreds of APMs across the world — a capability that is essential for success in today’s demanding and evolving eCommerce landscape.
                  <br /><br />
                  To find out more about how we can help your business, get in touch by clicking below.</p>
                <button className={classes.btn} onClick={() => window.open('https://www.aciworldwide.com/about-aci/contact-sales', '_blank')}>Contact Us</button>
                
              </div>
              <div className={classes.nextStep2Right}>
                <div className={classes.nextStep2RightIframe}>
                  <div>
                    <iframe src="https://player.vimeo.com/video/432966633?h=a79895c671&autoplay=1&loop=1&byline=0&title=0" allowFullScreen />
                  </div>
                </div>
                {/* <img src={ASSETS.RESULTS_NEXT_STEP} alt=""
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    window.open('https://www.aciworldwide.com/solutions/aci-secure-ecommerce', '_blank')
                  }}  
                /> */}
              </div>
            </div>
            <Logo bottom />
            <div className={classes.copyrightContainer}>
                <p>
                  © Copyright ACI Worldwide, Inc. 2022<br />
                  ACI, ACI Worldwide, ACI Payments, Inc., ACI Pay, Speedpay and all ACI product/solution names are trademarks or registered trademarks of ACI Worldwide, Inc.,
                  or one of its subsidiaries, in the United States, other countries or both. Other parties’ trademarks referenced are the property of their respective owners.
                </p>
              </div>
          </div>
        </FullSizeContainer>}
    </>
  );
};

export default SelectReport;
