import React, { useState, useEffect, useMemo } from 'react';
import {
  FullSizeContainer,
  AbsoluteFillContainer,
  EnterDetailNewFlow,
  CreditCardAndPaymentMethods,
  Dialog,
} from 'components';
import { ASSETS, COLOR } from 'const';
import { IDataSource, ICardData, ICountryInputData as ICountryData } from 'type';
import homeclasses from './Home.module.scss';
import classes from './InputCountryData.module.scss';
import ReportManager from './ReportManager';

const InputCountryData = () => {
  const [dialogState, setDialogState] = useState({
    open: false,
    message: '',
  });

  const [selectedCountries, setSelectedCountries] = useState<Array<string>>([]);
  const [currency, setCurrency] = useState({ cc: "USD", symbol: "$", name: "United States dollar", rate: 1 });
  const [isReady, setIsReady] = useState(false);
  const [focusIndex, setFocusIndex] = useState(0);

  const [sourceData, setSourceData] = useState<IDataSource>();
  const [countryData, setCountryData] = useState<ICountryData>({});
  const saveCountryData = (value : ICountryData) => {
    setCountryData(value);
    localStorage.setItem('InputCountryData_countryData', JSON.stringify(value));
  };

  const focusedCountry = selectedCountries[focusIndex];
  const focusedCountryData = (() => {
    if (!focusedCountry) return undefined;
    return countryData[focusedCountry];
  })();

  const cardDataByCountry : ICardData = useMemo(() => {
    if (!sourceData || Object.keys(sourceData).length === 0) return {};
    if (!sourceData['2.2 ecomm card split']) return {};
    const result : ICardData = {};
    sourceData['2.2 ecomm card split'].forEach(c => {
      const findCountry = sourceData['1.1 Ecommerce split'].find(item => item.country === c.country);
      result[c.country] = {
        ...c,
        paymenyMethods: !findCountry ? [] : findCountry.data.filter(item => item.name !== 'Credit card' && item.name !== 'Debit card'),
      }
    });
    return result;
  }, [sourceData]);

  const focusedCountryCardData = useMemo(() => {
    if (!focusedCountry) return undefined;
    return cardDataByCountry[focusedCountry];
  }, [focusedCountry, cardDataByCountry]);

  const onChangeFocusCountryData = (obj : any) => {
    if (!focusedCountry) return;
    const newData = !focusedCountryData ? {
      revenue: 0,
      data: {
        'Credit Card': [],
        'Debit Card': [],
        'Alternative Payment Methods': [],
      },
    } : focusedCountryData;
    for (let key in obj) {
      newData[key] = obj[key];
    }
    saveCountryData({
      ...countryData,
      [focusedCountry]: newData,
    })
  };

  const submitData = () => {
    if (!sourceData) return alert('Please try again shortly');
    const { valid, message } = ReportManager.verifyInput(countryData);
    if (!valid) return alert(message);
    ReportManager.generateReport(countryData, sourceData, currency);
    window.location.pathname = '/select-report';
  };

  useEffect(() => {
    fetch('https://api.aci-calculator.devserver.london/data')
    .then(res => res.json())
    .then(json => {
      if (json.success && Object.keys(json.data).length > 0) {
        setSourceData(json.data);
        console.log(json.data);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }, []);

  useEffect(() => {
    const stringCountries = localStorage.getItem('selected_countries');
    try {
      if (!!stringCountries) {
        const newCountries = JSON.parse(stringCountries);
        setSelectedCountries(newCountries);
      }
    } catch(err){}

    const stringCurrency = localStorage.getItem('selected_currency');
    try {
      if (!!stringCurrency) {
        const newCurrency = JSON.parse(stringCurrency);
        setCurrency(newCurrency);
      }
    } catch(err){}

    const stringCountryData = localStorage.getItem('InputCountryData_countryData');
    try {
      if (!!stringCountryData) {
        const newCountryData = JSON.parse(stringCountryData);
        setCountryData(newCountryData);
      }
    } catch(err){}

    setIsReady(true);
  }, []);

  if (window.innerWidth <= 768) {
    return (
      <FullSizeContainer bgColor={COLOR.DARK}>
        <AbsoluteFillContainer middle>
          <h3 className={homeclasses.selectCountriesInstruction}>Please use Desktop to view this section </h3>
        </AbsoluteFillContainer>
      </FullSizeContainer>
    )
  };

  if (!isReady) {
    return (
      <FullSizeContainer bgColor={COLOR.DARK}>
        <AbsoluteFillContainer middle>
          <h3 className={homeclasses.selectCountriesInstruction}>Loading...</h3>
        </AbsoluteFillContainer>
      </FullSizeContainer>
    )
  }

  if (!selectedCountries || !Array.isArray(selectedCountries) || selectedCountries.length === 0) {
    return (
      <FullSizeContainer bgColor={COLOR.DARK}>
        <AbsoluteFillContainer middle>
          <h3 className={homeclasses.selectCountriesInstruction}>Please select country in the <a href="/" style={{ color: 'white' }}>previous</a> step</h3>
        </AbsoluteFillContainer>
      </FullSizeContainer>
    );
  }

  return (
    <>
      <FullSizeContainer
        bgColor={COLOR.DARK}
        style={{
          backgroundImage: `url("${ASSETS.COUNTRY_DATA_BG}")`,
          // backgroundPosition: '100% -50%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <EnterDetailNewFlow
          country={focusedCountry}
          currency={currency}
          data={focusedCountryData}
          onChange={onChangeFocusCountryData}
          cardData={focusedCountryCardData}
          onClickContinue={() => {
            console.log('focusedCountryData', focusedCountryData);
            const { data } = focusedCountryData || { data: {} };
            console.log('data', data);
            const lengths = Object.keys(data).map(key => data[key].length || 0);
            const toltalLengths = lengths.length === 0 ? 0 : lengths.reduce((a,b) => a+b);
            // const apm = data['Alternative Payment Methods'] || [];
            if (toltalLengths === 0) {
              setDialogState({
                open: true,
                message: 'Please select your supported payment methods',
              })
              return;
            }
            if (focusIndex === selectedCountries.length - 1) {
              submitData();
            } else {
              setFocusIndex(focusIndex + 1)
            }
          }}
        />
        <div className={classes.copyrightContainer}>
          <p>
          © Copyright ACI Worldwide, Inc. 2022<br />
ACI, ACI Worldwide, ACI Payments, Inc., ACI Pay, Speedpay and all ACI product/solution names are trademarks or registered trademarks of ACI Worldwide, Inc., 
or one of its subsidiaries, in the United States, other countries or both. Other parties’ trademarks referenced are the property of their respective owners.
          </p>
        </div>
      </FullSizeContainer>
      <Dialog
        {...dialogState}
        onClose={() => {
          setDialogState(v => ({ ...v, open: false }));
        }}
      />
    </>
  );
};

export default InputCountryData;
