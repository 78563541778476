import { CSSProperties } from 'react';
import classes from './FullSizeContainer.module.scss';
import cs from 'classnames';
interface IFullSizeContainerProps {
  children?: any,
  bgColor?: string,
  style?: CSSProperties,
  className?: string,
}

const FullSizeContainer = (props : IFullSizeContainerProps) => {
  return (
    <div className={cs(classes.container, props.className)} style={{ backgroundColor: props.bgColor, ...props.style }}>
      <div className={classes.container_inner}>
        {props.children}
      </div>
    </div>
  );
};

export default FullSizeContainer;