import HomePageArt from './svg/HomePageArt';
import DownArrow from './svg/DownArrow';
import HomeBannerArrowAnim from './svg/HomeBannerArrowAnim';

export const SVG = {
  HomePageArt,
  DownArrow,
  HomeBannerArrowAnim,
}

export const ASSETS = {
  LOGO: require('./img/logo.png').default,
  HOME_ART_WORK: require('./img/homeartwork.png').default,
  START_CURSOR: require('./img/start-cursor.png').default,
  HOME_DECOR_ICON: require('./img/home-decor-icon.png').default,
  HOME_DECOR_2: require('./img/home-decor-2.png').default,
  RESULTS_DART_WORK: require('./img/results-artwork.png').default,
  DOUBLE_ARROW_DOWN: require('./img/double-arrow-down.png').default,
  DOUBLE_ARROW_DOWN_DARK: require('./img/double-arrow-down-dark.png').default,
  RESULTS_OVERVIEW_ARTWORK: require('./img/results-overview-artwork.png').default,
  RESULTS_APMS_ARTWORK: require('./img/results-apms-artwork.png').default,
  RESULTS_CARD_SCHEME_ARTWORK: require('./img/results-card-schemes-artwork.png').default,
  RESULTS_CARD_LINE: require('./img/results-card-line.png').default,
  RESULTS_CHART_DEMO: require('./img/results-chart-demo.png').default,
  RESULTS_CHART_DEMO_2: require('./img/results-chart-demo-2.png').default,
  RESULTS_NEXT_STEP: require('./img/results-next-steps.png').default,
  RESULTS_NEXT_STEP_ARTWORK: require('./img/results-next-steps-artwork.png').default,
  SAMPLE_EXCEL: require('./740985 - ACI ecommerce calculator data v3.xlsx').default,
  RESULTS_NEXT_ARROW: require('./img/right-arrow-green.png').default,

  VIDEO_HEADER_MAIN: require('./videos/aci_header_main_v4.webm').default,
  FORM_BG: require('./img/form_bg.png').default,
  COUNTRY_DATA_BG: require('./img/country_data_bg.png').default,

  THUMB_MEXICO: require('./img/mexico_thumb.jpg').default,
  THUMB_ARGENTINA: require('./img/argentina_thumb.jpg').default,
  THUMB_CHILE: require('./img/chile_thumb.jpg').default,
  THUMB_BRAZIL: require('./img/brazil_thumb.jpg').default,
  THUMB_COLOMBIA: require('./img/colombia_thumb.jpg').default,
  THUMB_RANDOM: require('./img/random_thumb.jpeg').default,

  VIDEO_OVERVIEW_GRAPHIC: require('./videos/aci_overview_graphic_v4.mp4').default,
  VIDEO_BAG: require('./videos/aci_bag_v3.mp4').default,
  VIDEO_CARDS: require('./videos/aci_cards_v3.mp4').default,
  VIDEO_GRAPH: require('./videos/aci_graph_v3.mp4').default,
  VIDEO_MONUMENTS: require('./videos/aci_monuments_v3.mp4').default,

  ALPHA_WEBM_VIDEO_HEADER_MAIN: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+Header+main.webm',
  ALPHA_MOV_VIDEO_HEADER_MAIN: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+Header+main.mov',

  ALPHA_WEBM_VIDEO_OVERVIEW_GRAPHIC: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+overview+graphic.webm',
  ALPHA_MOV_VIDEO_OVERVIEW_GRAPHIC: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+overview+graphic.mov',

  ALPHA_WEBM_VIDEO_BAG: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+bag.webm',
  ALPHA_MOV_VIDEO_BAG: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+bag.mov',

  ALPHA_WEBM_VIDEO_CARDS: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+cards_1.webm',
  ALPHA_MOV_VIDEO_CARDS: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+cards.mov',

  ALPHA_WEBM_VIDEO_GRAPH: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+graph.webm',
  ALPHA_MOV_VIDEO_GRAPH: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+graph.mov',

  ALPHA_WEBM_VIDEO_MONUMENTS: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+Header+Monuments.webm',
  ALPHA_MOV_VIDEO_MONUMENTS: 'https://aci-calculator-site.s3.eu-west-1.amazonaws.com/video/ACI+Header+Monuments.mov',

  BLUE_ARROW_DROPDOWN: require('./img/blue-arrow-dropdown.png').default,
};

