import { SVGProps } from "react"

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26.375}
    height={15.309}
    {...props}
  >
    <path
      data-name="Path 1135"
      d="M25.315 1.061 13.188 13.188 1.061 1.061"
      fill="none"
      stroke="#26d7da"
      strokeMiterlimit={10}
      strokeWidth={3}
    />
  </svg>
)

export default SvgComponent
