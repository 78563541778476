import React, { useState } from 'react';
import classes from './EnterDetailNewFlow.module.scss';
import { COLOR, ASSETS, SVG, useListCurrencies } from 'const';
import CountryThumb from './CountryThumb';
import CreditCardAndPaymentMethods from '../select/CreditCardAndPaymentMethods';
import { ICardDataSingleCountry } from 'type';

interface IProps {
  country: string,
  data?: {
    revenue: number,
    data: {
      [type: string]: Array<string>,
    },
    [any : string]: any,
  },
  currency: {
    cc: string,
    name: string,
    symbol: string,
  },
  onClickContinue?(): void,
  onChange(newData : any): void,
  cardData?: ICardDataSingleCountry,
}

const EnterDetailNewFlow = (props : IProps) => {
  return (
    <div className={classes.container}>
      <CountryThumb name={props.country} />
      <div className={classes.mainDetails}>
        <div className={classes.inputRevenue}>
          <p><SVG.DownArrow /> Input Country Revenue</p>
          <div className={classes.inputContainer}>
            <input
              value={props.data?.revenue || '0'}
              type='number'
              onChange={(e) => {
                let value = e.target.value;
                if (value.length > 1 && value[0] === "0") {
                  value = value.slice(1, value.length);
                }
                props.onChange({
                  revenue: value,
                });
              }}
            />
            <span>{props.currency.symbol}</span>
          </div>
        </div>
        <div className={classes.selectPaymentMethods}>
          <p><SVG.DownArrow /> Select Your Supported Payment Methods</p>

          <CreditCardAndPaymentMethods
            data={props.data}
            onChange={props.onChange}
            cardData={props.cardData}
            className={classes.creditCardAndPaymentMethods}
          />
        </div>
        <div className={classes.continue}>
          <button className={classes.continueBtn} onClick={props.onClickContinue}>Continue</button>
        </div>
      </div>
    </div>
  );
};

export default EnterDetailNewFlow;