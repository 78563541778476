import classes from './RatioContainer.module.scss';
import cls from 'classnames';

interface IRatioDivProps {
  ratio: number; // width / height
  children?: any;
  width: number | string;
  className?: string;
  style?: React.CSSProperties;
  [anyProp: string]: any;
}

export const RatioContainer = (props: IRatioDivProps) => {
  return (
    <div className={cls(classes.container, props.className)} style={{ ...props.style, width: props.width }}>
      <div
        style={{
          paddingBottom: 100 / props.ratio + '%',
          position: 'relative',
        }}
      >
        <div className={classes.absoluteFill}>{props.children}</div>
      </div>
    </div>
  );
};
