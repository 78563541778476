import { useState, useEffect } from 'react';

export const useRatioDimensions = (ratio = 1920 / 1080) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      const { innerWidth, innerHeight } = window;
      const containerHeight = innerHeight;
      const containerWidth = innerWidth;
      const newWidth = containerWidth / containerHeight < ratio ? containerWidth : containerHeight * ratio;
      if (newWidth !== width) setWidth(newWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [width, ratio]);
  return width;
};
