import { LIST_COUNTRIES } from 'const';
import { IDataSource } from 'type';

interface IWorksheet {
  id: number,
  orderNo: number,
  name: string,
  [other: string]: any,
}

const PROCESS_ROW_RANGE =1500;

const isCountry = (name : string) => {
  return !!LIST_COUNTRIES.find(c => c.name === name);
};

export const processExcel = (worksheets : Array<IWorksheet>) : IDataSource => {

  const data : IDataSource = {
    '1.1 Ecommerce split': [],
    '1.2 Market growth': [],
    '2.1 Instore payment split': [],
    '2.2 ecomm card split': [],
  };

  const index : any = {
    '1.1 Ecommerce split': -1,
    '1.2 Market growth': -1,
    '2.1 Instore payment split': -1,
    '2.2 ecomm card split': -1,
  };

  // check if excel file contains correct tab name
  for (let name in data) {
    const i = worksheets.findIndex((val : any) => val.name === name);
    if (i === -1) throw new Error('Can not find tab: ' + name);
    index[name] = i;
  }

  const getCellValueFromRow = (rowData : any) => (column : any, log = false) => {
    const value = rowData.getCell(column).value;
    const hyperlink = rowData.getCell(column).hyperlink;
    if (log) {
      console.log(rowData.getCell(column));
    }
    if (!value) return '';
    if (!!hyperlink) return value.hyperlink;
    if (typeof value === 'string' || typeof value === 'number') return value;
    if (!!value.formula && value.formula.indexOf('hyperlink') === 0) {
      const reg = /hyperlink\(".*",/g;
      const match = value.formula.match(reg);
      if (match && match[0]) {
        const foo = match[0].slice("hyperlink(\"".length, match[0].length)
        const bar = foo.slice(0, foo.length -2);
        return bar;
      }
    }
    if (!!value.result) return value.result;
    return '';
  }

  // 1.1 Ecommerce split
  data['1.1 Ecommerce split'] = (() => {
    const items : Array<any> = [];
    let currentCountryIndex = -1;
    const worksheet = worksheets[index['1.1 Ecommerce split']];

    for (let row = 1; row < PROCESS_ROW_RANGE; row++) {
      const rowData = worksheet.getRow(row);
      const cell = getCellValueFromRow(rowData);

      console.log(cell(1), cell(2), cell(3))

      // verify title
      if (row === 1) {
        if (cell(1) !== 'Row Labels' || cell(2) !== 'Sum of 2020' || cell(3) !== 'Value (USD Mn)') 
          throw new Error('Please make sure the column titles of 1.1 Ecommerce split are exactly like the sample excel');
        continue;
      }

      if (!cell(1)) continue;

      if (isCountry(cell(1))) {
        currentCountryIndex++;
        items.push({
          country: cell(1),
          sum: cell(2),
          value: cell(3),
          data: [],
        });
      } else {
        items[currentCountryIndex].data.push({
          name: cell(1),
          sum: cell(2),
          value: cell(3),
        })
      }

    }

    return items;
  })();

  // 1.2 Market growth
  data['1.2 Market growth'] = (() => {
    const items : Array<any> = [];
    let currentCountryIndex = -1;
    const worksheet = worksheets[index['1.2 Market growth']];

    for (let row = 1; row < PROCESS_ROW_RANGE; row++) {
      const rowData = worksheet.getRow(row);
      const cell = getCellValueFromRow(rowData);

      if (row === 1) {
        if (cell(1) !== 'Country' ||
          cell(2) !== 'Market Size 2018' ||
          cell(3) !== 'Market Size 2019' ||
          cell(4) !== 'Market Size 2020' ||
          cell(5) !== 'Market Size 2021' ||
          cell(6) !== 'Market Size 2022' ||
          cell(7) !== 'Growth 2018 to 2022'
        ) throw new Error('Please make sure the column titles of 1.2 Market growth are exactly like the sample excel');
        continue;
      }

      if (isCountry(cell(1))) {
        currentCountryIndex++;
        items.push({
          country: cell(1),
          data: [],
        });
      } else {
        items[currentCountryIndex].data.push({
          name: cell(1),
          size2018: cell(2),
          size2019: cell(3),
          size2020: cell(4),
          size2021: cell(5),
          size2022: cell(6),
          growth20182022: cell(7),
        })
      }

    }

    return items;
  })();

  // 2.1 Instore payment split
  data['2.1 Instore payment split'] = (() => {
    const items : Array<any> = [];
    let currentCountryIndex = -1;
    const worksheet = worksheets[index['2.1 Instore payment split']];

    for (let row = 1; row < PROCESS_ROW_RANGE; row++) {
      const rowData = worksheet.getRow(row);
      const cell = getCellValueFromRow(rowData);

      if (row === 1) {
        if (cell(1) !== 'Row Labels' ||
          cell(2) !== '% share of Payment Tool/Method for 2020'
        ) throw new Error('Please make sure the column titles of 2.1 Instore payment split are exactly like the sample excel');
        continue;
      }

      if (isCountry(cell(1))) {
        currentCountryIndex++;
        items.push({
          country: cell(1),
          data: [],
        });
      } else {
        items[currentCountryIndex].data.push({
          name: cell(1),
          value: cell(2),
        })
      }
    }

    return items;
  })();

  // 2.2 ecomm card split
  data['2.2 ecomm card split'] = (() => {
    const items : Array<any> = [];
    let currentCountryIndex = -1;
    const currentCardType = { type: '' };
    const worksheet = worksheets[index['2.2 ecomm card split']];

    for (let row = 1; row < PROCESS_ROW_RANGE; row++) {
      const rowData = worksheet.getRow(row);
      const cell = getCellValueFromRow(rowData);

      if (row === 1) {
        if (cell(1) !== 'Row Labels' ||
          cell(2) !== `eCommerce Market Size\n(in Mn USD)` ||
          cell(3) !== '%age usage within card segment'
        ) throw new Error('Please make sure the column titles of 2.2 ecomm card split are exactly like the sample excel');
        continue;
      }

      if (isCountry(cell(1))) {
        currentCountryIndex++;
        items.push({
          country: cell(1),
          creditCard: [],
          debitCard: [],
        });
      } else if (cell(1) === 'Credit Card') {
        currentCardType.type = 'credit';
      } else if (cell(1) === 'Debit Card') {
        currentCardType.type = 'debit';
      } else {
        if (currentCardType.type === 'credit') {
          items[currentCountryIndex].creditCard.push({
            name: cell(1),
            size: cell(2),
            usage: cell(3),
          })
        } else if (currentCardType.type === 'debit') {
          items[currentCountryIndex].debitCard.push({
            name: cell(1),
            size: cell(2),
            usage: cell(3),
          })
        }
      }
    }

    return items;
  })();

  console.log(data);

  return data;
};
