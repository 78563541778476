import './App.scss';

import { Home, Results, ImportData, SingleReport, Home2, SelectCountry, InputCountryData, SelectReport, ViewSingleReport, Download, ViewAllReport, ImageCheck, Responsive1, DebugVideo } from 'screens';

const App = () => {
  return window.location.pathname === '/results' ? <Results /> : 
    window.location.pathname === '/import' ? <ImportData /> :
    window.location.pathname.includes('/report') ? <SingleReport /> :
    window.location.pathname.includes('/select-country') ? <SelectCountry /> :
    window.location.pathname.includes('/input-country-data') ? <InputCountryData /> :
    window.location.pathname.includes('/home2') ? <Home2 /> :
    window.location.pathname.includes('/select-report') ? <SelectReport /> :
    window.location.pathname.includes('/view-single-report') ? <ViewSingleReport /> :
    window.location.pathname.includes('/download') ? <Download /> :
    window.location.pathname.includes('/view-all-report') ? <ViewAllReport /> :
    window.location.pathname.includes('/image-check') ? <ImageCheck /> :
    window.location.pathname.includes('/responsive-1') ? <Responsive1 /> :
    window.location.pathname.includes('/debug/video') ? <DebugVideo /> :
    window.location.pathname.includes('/home') ? <Home /> :
    // this is the default route
    <Responsive1 />
};

export default App;