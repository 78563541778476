import * as React from "react";

import classes from './HomeBannerArrowAnim.module.scss';

const SvgComponent = (props: any) => {
  const mainColor = props.color || "#fff";
  return (
    <svg
      data-name="Component 15 \u2013 2"
      xmlns="http://www.w3.org/2000/svg"
      width={window.innerWidth === 1920 ? 69 : 45}
      height={window.innerWidth === 1920 ? 69 : 45}
      viewBox="0 0 69 69"
      style={{
        cursor: 'pointer',
        marginTop: 10,
      }}
      className={classes.svgAnimation}
      {...props}
    >
      <g fill="none" stroke={mainColor} strokeWidth={3}>
        <circle cx={34.5} cy={34.5} r={34.5} stroke="none" />
        <circle cx={34.5} cy={34.5} r={33} />
      </g>
      <g id="arrow_2" className={classes.arrow2} fill="none" stroke={mainColor} strokeLinecap="round" strokeWidth={3}>
        <path data-name="Line 813" d="M20.358 36.358 34.5 50.5" />
        <path data-name="Line 814" d="M48.642 36.358 34.5 50.5" />
      </g>
      <g id="arrow_1" className={classes.arrow1} fill="none" stroke={mainColor} strokeLinecap="round" strokeWidth={3}>
        <path data-name="Line 813" d="M20.358 20.358 34.5 34.5" />
        <path data-name="Line 814" d="M48.642 20.358 34.5 34.5" />
      </g>
    </svg>
  );
}

export default SvgComponent;
