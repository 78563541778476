import React, { useState, useEffect } from 'react';
import {
  FullSizeContainer,
  Logo,
  AbsoluteFillContainer,
  ResultComps,
  getCountryThumb,

} from 'components';
import { CardSchemeChart } from 'components/results';
import classes from './Results.module.scss';
import utilClasses from '../Utils.module.scss';
import { COLOR, ASSETS, SVG } from 'const';
import { IReportData, IReportDataSingleCountry, IAllData } from 'type';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import cs from 'classnames';

const ViewAllReport = () => {
  const reportId = (() => {
    const parts = window.location.pathname.split('/');
    return parts[parts.length - 1];
  })();
  return (
    <>
      {reportId}
    </>
  );
};

export default ViewAllReport;
