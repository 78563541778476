import list_contries from './list_countries.json';
import list_currencies from './list_currencies.json';
import { LIST_PROVIDED_COUNTRIES } from './list_provided_countries';
import { useState, useEffect } from 'react';

export const HOST = 'https://api.aci-calculator.devserver.london';
// export const HOST = 'http://localhost:4000';

export const FONTS = {
  REGULAR: 'Neue-Plak-Text',
  BOLD: 'Neue-Plak-Text-Bold',
  SEMI_BOLD: 'Neue-Plak-Text-SemiBold',
};

export const COLOR = {
  MAIN: 'var(--color-main)',
  SECOND: 'var(--color-second)',
  WHITE: 'white',
  DARK: 'var(--color-dark)',
};

export const LIST_COUNTRIES = LIST_PROVIDED_COUNTRIES;

export const useListCurrencies = () => {
  const [list, setList] = useState<Array<{ rate: number; cc: string; symbol: string; name: string; }>>([]);
  useEffect(() => {
    fetch('https://api.apilayer.com/fixer/latest?base=USD', {
      headers: {
        'apiKey': 'gRu8s8SHOLF3iSrp74saZZCS8C4bLHrT'
      },
    })
    .then(res => res.json())
    .then(json => {
      const newList : Array<{ rate: number; cc: string; symbol: string; name: string; }> = [];
      const { rates } = json;

      ['USD', 'AUD', 'CNY', 'EUR', 'GBP', 'JPY'].forEach(key => {
        const findCurrency = list_currencies.find(val => val.cc === key);
        if (!!findCurrency) newList.push({ ...findCurrency, rate: rates[key] });
      })
      // const findUSD = list_currencies.find(val => val.cc === 'USD');
      // if (!!findUSD) newList.push({ ...findUSD, rate: 1 });
      // for (let key in rates) {
      //   const findCurrency = list_currencies.find(val => val.cc === key);
      //   if (!!findCurrency) newList.push({ ...findCurrency, rate: rates[key] });
      // }
      setList(newList);
    });
  }, []);

  return list;
}



export * from './assets';