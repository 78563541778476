import React, { useState, useEffect } from 'react';
import {
  FullSizeContainer, Logo, AbsoluteFillContainer, Select, SelectCountries,
  EnterDetails, CurrentSelection, CreditCardAndPaymentMethods, Dialog,
} from 'components';
import classes from './Download.module.scss';
import { COLOR, ASSETS, SVG, HOST } from 'const';
import cls from 'classnames';
import { IReportData, IReportDataSingleCountry, IAllData } from 'type';
import { saveAs } from 'file-saver';

const Download = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [data, setData] = useState<IAllData>();
  const [countries, setCountries] = useState<Array<string>>([]);
  const [selected, setSelected] = useState<Array<string>>([]);
  const [downloadLinks, setDownloadLinks] = useState<Array<{ name: string, url: string }>>([]);

  useEffect(() => {
    const savedData = localStorage.getItem('data');
    try {
      if (!savedData) return;
      const parsedSaveData: IAllData = JSON.parse(savedData)
      setData(parsedSaveData);
    } catch (err) { }
  }, []);

  useEffect(() => {
    const stringCountries = localStorage.getItem('selected_countries');
    try {
      if (!!stringCountries) {
        const newCountries = JSON.parse(stringCountries);
        setCountries(newCountries);
      }
    } catch (err) { }
  }, []);

  const downloadFile = (url: string, name: string) => {
    var link = document.createElement('a');
    link.href = url;
    link.download = name + '.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }

  const getReport = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    const results: Array<{ name: string, url: string }> = [];
    const promiseArr = selected.map(async (val) => {
      try {
        const selectedCountry = val;
        const findDownload = downloadLinks.find(val => val.name === selectedCountry);
        if (findDownload) {
          results.push({ ...findDownload });
          return;
        }
        const countryData = data?.reportData[selectedCountry];
        const res = await fetch(`${HOST}/get-report-id`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              name: selectedCountry,
              data: countryData,
            },
          }),
        });
        const json = await res.json();
        const url = `${HOST}/report/pdf/${json.id}`;
        results.push({ name: selectedCountry, url });
        // downloadFile(url, `Report_${val}`);
        // const resPDF = await fetch(url);
        // const blob = await resPDF.blob();
        await saveAs(url, `Report_${val}.pdf`);
      } catch (err) { }
    });
    await Promise.all(promiseArr);
    setDownloadLinks(results);
    setIsDownloading(false);
  }

  return (
    <>
      <FullSizeContainer
        bgColor={COLOR.DARK}
        className={classes.bgContainer}
        style={{
          backgroundImage: `url("${ASSETS.FORM_BG}")`,
        }}
      >
        <Logo />
        <h3 className={classes.heading}>Select your reports to download</h3>
        <div className={classes.downloadContainer}>
          <div>
            <p><SVG.DownArrow /> Select Your Reports</p>

            <div className={classes.countryList}>
              {countries.map((country, i) => {
                const isSelected = !!selected.find(val => val === country);
                return (
                  <>
                    <div
                      className={cls(classes.item, { [classes.selected]: isSelected })}
                      key={country}
                      onClick={() => {
                        if (isSelected) setSelected(v => v.filter(item => item !== country))
                        else {
                          const newSelected = selected.slice();
                          newSelected.push(country);
                          setSelected(newSelected);
                        }
                      }}
                    >
                      {country}
                      {isSelected && (
                        <div className={classes.deselectItemIcon}>
                          ✖
                        </div>
                      )}
                    </div>
                  </>
                )
              })}
            </div>
          </div>
          <div className={classes.btn}>
            <button className={classes.download} onClick={getReport}>{isDownloading ? 'Downloading...' : 'Download'}</button>
            <a href="/"><button className={classes.download}>Start Again</button></a>
          </div>
          {/* <div className={classes.countryList} style={{ marginTop: 45 }}>
            {downloadLinks.map((link, i) => {
              return (
                <>
                  <div
                    className={cls(classes.item)}
                    key={link.name}
                    onClick={() => {
                      window.open(link.url, '_blank');
                    }}
                    style={{
                      color: '#FFCB00',
                    }}
                  >
                    {link.name} PDF ↓
                  </div>
                </>
              )
            })}
          </div> */}
        </div>
      </FullSizeContainer>
    </>
  );
};

export default Download;