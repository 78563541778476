
import { ASSETS } from 'const';
import classes from './Logo.module.scss';
import cs from 'classnames';

interface IProps {
  bottom?: boolean;
}

const Logo = (props : IProps) => {
  return (
    <a href='/' className={cs(classes.absolute_container, props.bottom && classes.bottom)}>
      <img src={ASSETS.LOGO} className={classes.logo} alt="ACI Calculator" />
    </a>
  );
};

export default Logo;