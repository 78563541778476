import { COLOR } from "const";
import Chart from "react-google-charts";
import classes from './CommerceGrowthChart.module.scss';

interface IProps {
  data: Array<{
    year: number,
    eCommerce: number,
    mCommerce: number,
  }>,
  growthPercentage: {
    eCommerce: number,
    mCommerce: number,
  },
}

export const CommerceGrowthChart = (props: IProps) => {

  const divide1000 = (val: number) => {
    return typeof val === 'number' ? val / 1000 : 0;
  }

  const chartData = props.data.map(val => [
    String(val.year),
    divide1000(val.eCommerce),
    ' point { size: 6; shape-type: circle; fill-color: transparent; visible: true; stroke-width: 1; stroke-color: #ffffff; }',
    divide1000(val.mCommerce),
    ' point { size: 6; shape-type: circle; fill-color: transparent; visible: true; stroke-color: #ffffff; }',
  ]);
  console.log('chartData', chartData);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <Chart
          width={window.innerWidth * 0.7}
          height={Math.max(350, window.innerHeight * 0.6)}
          chartType="LineChart"
          loader={<div style={{ color: 'white' }}>Loading Chart</div>}
          data={[
            ['Year', 'E-Commerce', { 'type': 'string', 'role': 'style' }, 'M-Commerce', { 'type': 'string', 'role': 'style' }],
            ...chartData,
          ]}
          options={{
            // pointShape: { type: 'triangle', rotation: 180 },
            hAxis: {
              // title: 'Year',
              titleTextStyle: { color: 'white' },
              textStyle: { color: 'white' },
              // gridlines: { color: '#2766D9'},
              // gridlineColor: '#2766D9',
            },
            vAxis: {
              title: 'Growth\n(1000s)',
              titleTextStyle: { color: 'white' },
              textStyle: { color: 'white' },
              // gridlines: { color: '#2766D9'},
              // gridlineColor: '#2766D9',
              gridlines: {
                color: '#2766D9',
              },
              minorGridlines: {
                color: 'transparent',
              },
            },
            series: {
              1: { curveType: 'function' },
            },
            curveType: 'function',
            backgroundColor: 'transparent',
            colors: [COLOR.WHITE, '#2680DA'],
            baselineColor: '#2766D9',
            chartArea: { width: '70%' },
            titleTextStyle: { color: '#ffffff' },
            legend: {
              position: 'none',
              textStyle: { color: 'white' },
            },
          }}
          rootProps={{ 'data-testid': '2' }}
        />
      </div>
      <div className={classes.descriptionContainer} style={{ width: window.innerWidth * 0.2 }}>
        <div style={{ width: 160, display: 'flex',flexDirection: 'column', alignItems: 'flex-end' }}>
          <div>
            <p><span className={classes.legendECommerce} /> eCommerce</p>
            <p><span className={classes.legendMCommerce} /> mCommerce</p>
          </div>
          <div className={classes.percentageCommerceContainer}>
            <p>eCommerce</p>
            <span className={classes.pEcommerce}>{props.growthPercentage?.eCommerce ? (props.growthPercentage?.eCommerce * 100).toFixed(2) + ' %' : ''}</span>
          </div>
          <div className={classes.percentageCommerceContainer}>
            <p>mCommerce</p>
            <span className={classes.pMcommerce}>{props.growthPercentage?.mCommerce ? (props.growthPercentage?.mCommerce * 100).toFixed(2) + ' %' : ''}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
