import React, { useRef, useEffect } from "react";
import isMobile from 'ismobilejs';
interface IVideoProps {
  srcs: Array<{
    src: string,
    type: string,
    device: 'safari' | 'chrome' | 'firefox' | string, // added this because the current does not work with Firefox
  }>,
  [otherProps: string]: any, // <video> props
}

export default function Video({ srcs, muted, ...props }: IVideoProps) {
  const refVideo = useRef<any>(null);
  useEffect(() => {
    if (!refVideo.current) {
      return;
    }
    if (muted) {
      //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
      refVideo.current.defaultMuted = true;
      refVideo.current.muted = true;
    }
    // refVideo.current.srcObject = src;
  }, [srcs]);

  const filterSrc = () => {
    if (!srcs) return [];
    // @ts-ignore
    const isFirefox = typeof InstallTrigger !== 'undefined';
    // @ts-ignore
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    
    const deviceText = isFirefox ? 'firefox' : isSafari ? 'safari' : 'chrome';
    return srcs.filter(val => val.device === '' || val.device.includes(deviceText));
  };

  return (
    <video
      ref={refVideo}
      {...props}
    >
      {filterSrc().map((val, i) => (
        <source key={'source-'+i} src={val.src} type={val.type} />
      ))}
    </video>
  );
}