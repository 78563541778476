import classes from './CurrentSelection.module.scss';
import { SVG } from 'const';
import { AiOutlineClose } from 'react-icons/ai'

interface ICurrrentSelectionProps {
  data: Array<{ name: string, isFocused: boolean }>,
  onChange?(newData : Array<{ name: string, isFocused: boolean }>): void,
}

const CurrentSelection = ({ data, onChange } : ICurrrentSelectionProps) => {

  const removeItem = (name : string) => {
    const index = data.findIndex(val => val.name === name);
      !!onChange && onChange([
        ...data.slice(0, index),
        ...data.slice(index + 1, data.length),
      ]);
  };

  const focusItem = (val : { name: string, isFocused: boolean }, index : number) => {
    !!onChange && onChange([
      ...data.slice(0, index).map(item => ({ name: item.name, isFocused: false })),
      { name: val.name, isFocused: true },
      ...data.slice(index + 1, data.length).map(item => ({ name: item.name, isFocused: false })),
    ]);
  }

  return (
    <div className={classes.container}>
      <div className={classes.countryBox}>
        {data.map((val, i) => (
          <div className={classes.selectedCountry} key={'current-'+i}>
            <span style={{ flex: 1 }} onClick={() => focusItem(val, i)}>
              {val.name}
            </span>
            <AiOutlineClose onClick={() => removeItem(val.name)} />
          </div>
        ))}
      </div>
      <div className={classes.instructionText}>
        <SVG.DownArrow />
        Current Selection
      </div>
    </div>
  );
};

export default CurrentSelection;