
import classes from './UnsupportedAPMColumn.module.scss';

interface IProps {
  name: string,
  value: string,
  percentage: string,
  rank: number,
}

export const UnsupportedAPMColumn = (props : IProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.firstCol}>
        <h6>{props.name}</h6>
        <h2># {props.rank}</h2>
      </div>
      <div className={classes.secondCol}>
        <h5>{props.percentage}</h5>
        <p>With an uplift value of</p>
        <h5>{props.value}</h5>
      </div>
    </div>
  );
};