import {
  FullSizeContainer, Logo, AbsoluteFillContainer, Select, SelectCountries,
  EnterDetails, CurrentSelection, CreditCardAndPaymentMethods, Dialog, getCountryThumb
} from 'components';
import { COLOR, ASSETS, SVG, useListCurrencies, LIST_COUNTRIES } from 'const';
import { useState, useEffect, useMemo, useRef } from 'react';
import utilClasses from '../Utils.module.scss';
import classes from './Home.module.scss';
import cs from 'classnames';
import { IDataSource, ICardData, ICountryInputData as ICountryData } from 'type';
import ReportManager from './ReportManager';
import cls from 'classnames';

const ImageCheck = () => {

  return (
    <>
      <FullSizeContainer bgColor={COLOR.DARK}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {LIST_COUNTRIES.map((val => (
            <div style={{ margin: 10 }}>
              <img src={getCountryThumb(val.name)} style={{ width: 100, height: 'auto', marginBottom: 10 }} />
              <h3 style={{ color: 'white' }}>{val.name}</h3>
            </div>
          )))}
        </div>
      </FullSizeContainer>
    </>
  );
}

export default ImageCheck;