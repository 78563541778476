import { IDataSource, ICardData, ICountryInputData, IReportData } from 'type';
import { VarHelper } from 'helpers';

class ReportManager {

  verifyInput = (inputData : ICountryInputData) => {
    // check revenue
    // const isInvalidRevenue = Object.keys(inputData).filter(c => !inputData[c].revenue).length > 0;
    // if (isInvalidRevenue) return { valid: false, message: 'Please fill in all revenue fields' };

    // check credit card
    // const isCreditCard = Object.keys(inputData).filter(c => !inputData[c].data['Credit Card']).length > 0;
    // if (isCreditCard) return { valid: false, message: 'Please choose at least one Credit Card' };

    // check debit card
    // const isDebitCard = Object.keys(inputData).filter(c => !inputData[c].data['Debit Card']).length > 0;
    // if (isDebitCard) return { valid: false, message: 'Please choose at least one Debit Card' };

    // // check debit card
    // const isAPMs = Object.keys(inputData).filter(c => !inputData[c].data['Alternative Payment Methods']).length > 0;
    // if (isAPMs) return { valid: false, message: 'Please choose at least one Alternative Payment Methods' };

    Object.keys(inputData).forEach(c => {
      if (!inputData[c].data['Credit Card']) inputData[c].data['Credit Card'] = [];
      if (!inputData[c].data['Debit Card']) inputData[c].data['Debit Card'] = [];
      if (!inputData[c].data['Alternative Payment Methods']) inputData[c].data['Alternative Payment Methods'] = [];
    })

    return { valid: true, message: '' };
  }

  generateReport = (inputData : ICountryInputData, sourceData : IDataSource, currency: { cc: string, name: string, symbol: string, rate: number }) => {
    console.log('inputData', inputData);
    console.log('sourceData', sourceData);

    const reportData : IReportData = {};

    for (let countryName in inputData) {

      const input = inputData[countryName];
      const sourceCountry = sourceData['1.1 Ecommerce split'].find(item => item.country === countryName);
      if (!sourceCountry) continue;
      const sourceCountryCardSchemes = sourceData['2.2 ecomm card split'].find(item => item.country === countryName);
      if (!sourceCountryCardSchemes) continue;

      sourceCountry.snapshotValue = sourceCountry.value;

      // replace revenue data
      if (input.revenue) {
        sourceCountry.value = input.revenue / 1E6;
        sourceCountry.data = sourceCountry.data.map(val => {
          val.value = val.sum * input.revenue / 1E6;
          return val;
        });
      }

      const listAPMsSourceCountry = sourceCountry.data.filter(item => item.name !== 'Credit card' && item.name !== 'Debit card');
      console.log('total listAPMsSourceCountry', listAPMsSourceCountry.reduce((a, b) => ({ ...a, sum: a.sum + b.sum })).sum);

      const inputPaymentMethods = input.data['Alternative Payment Methods'] || [];
      
      const totalPercentage = sourceCountry.data.reduce((a, b) => ({ ...a, sum: a.sum + b.sum })).sum;
      console.log('totalPercentage', totalPercentage);

      const listPaymentMethodObject = inputPaymentMethods.map(p => {
        const findPayment = sourceCountry.data.find(item => item.name === p);
        console.log('findPayment', findPayment, p);
        if (!findPayment) return { sum: 0, value: 0, name: '' };
        return findPayment;
      });
      const cardObjects = (() => {
        const findCredit = sourceCountry.data.find(item => item.name === 'Credit card');
        const findDebit = sourceCountry.data.find(item => item.name === 'Debit card');
        return {
          credit: findCredit,
          debit: findDebit,
        }
      })();

      const listUnsupportedAPM : Array<{ name: string, sum: number, value: number }> = sourceCountry.data.filter(item =>
        item.name !== 'Credit card' &&
        item.name !== 'Debit card' &&
        !inputPaymentMethods.includes(item.name)
      ).sort((a, b) => a.value > b.value ? -1 : 1);
      
      const listCreditCard = input.data['Credit Card'].map(p => {
        const findCard = sourceCountryCardSchemes.creditCard.find(item => item.name === p);
        if (!findCard) return { name: '', size: 0, usage: 0 };
        return findCard;
      }).filter(val => !!val.name);

      const listDebitCard = input.data['Debit Card'].map(p => {
        const findCard = sourceCountryCardSchemes.debitCard.find(item => item.name === p);
        if (!findCard) return { name: '', size: 0, usage: 0 };
        return findCard;
      }).filter(val => !!val.name);


      // @ts-ignore
      reportData[countryName] = {
        currency,
        overview: (() => {
          console.log('listPaymentMethodObject', listPaymentMethodObject);
          const percentageAPM = listPaymentMethodObject.length === 0 ? 0 : listPaymentMethodObject.reduce((a, b) => {
            return {
              value: 0,
              name: '',
              sum: a.sum + b.sum,
            };
          }).sum;

          console.log('percentageAPM', percentageAPM);

          let percentageCreditCard = listCreditCard.length === 0 ? 0 : listCreditCard.reduce((a, b) => {
            return {
              size: 0,
              name: '',
              usage: a.usage + b.usage,
            };
          }).usage;
          if (percentageCreditCard > 0.9999) percentageCreditCard = 1;

          let percentageDebitCard = listDebitCard.length === 0 ? 0 : listDebitCard.reduce((a, b) => {
            return {
              size: 0,
              name: '',
              usage: a.usage + b.usage,
            };
          }).usage;
          if (percentageDebitCard > 0.9999) percentageDebitCard = 1;

          const percentage = percentageAPM
            + (!cardObjects.credit ? 0 : cardObjects.credit.sum * percentageCreditCard)
            + (!cardObjects.debit ? 0 : cardObjects.debit.sum * percentageDebitCard);

          const revenuePaymentMethods = sourceCountry.value
          - (sourceCountry.data.find(item => item.name === 'Credit card')?.value || 0)
          - (sourceCountry.data.find(item => item.name === 'Debit card')?.value || 0);

          let revenueNumber = (revenuePaymentMethods - (listPaymentMethodObject.length === 0 ? 0 : listPaymentMethodObject.reduce((a, b) => {
            return {
              value: a.value + b.value,
              sum: 0,
              name: '',
            }
          }).value)) * currency.rate;
          if (revenueNumber < 0) revenueNumber = 0; 

          // const revenue = `${currency.symbol} ${Math.floor(revenueNumber)}`;
          const revenue = VarHelper.formatMoneyMilion(revenueNumber, currency.symbol);

          return {
            percentage,
            revenue,
            revenueNumber,
          }
        })(),
        APMs: {
          topUnsupported: listUnsupportedAPM.map((item, itemIndex) => ({
            name: item.name,
            rank: itemIndex + 1,
            percentage: item.sum,
            revenueNumber: item.value * currency.rate,
            // revenue: `${currency.symbol} ${Math.floor(item.value * currency.rate)}`,
            revenue: VarHelper.formatMoneyMilion(item.value * currency.rate, currency.symbol),
          })).slice(0, 3),
          additional: listUnsupportedAPM.slice(3, listUnsupportedAPM.length).map(item => ({
            name: item.name,
            percentage: item.sum,
          })).slice(0, 5),
        },
        cardSchemes: {
          creditCardPercentage: listCreditCard.length === 0 ? 0 : listCreditCard.reduce((a,b) => ({ ...a, usage: a.usage + b.usage })).usage,
          debitCardPercentage: listDebitCard.length === 0 ? 0 : listDebitCard.reduce((a,b) => ({ ...a, usage: a.usage + b.usage })).usage,
          topUnsupportedCardSchemes: (() => {
            let creditList = sourceCountryCardSchemes.creditCard.filter(item => !input.data['Credit Card'].includes(item.name));
            let debitList = sourceCountryCardSchemes.debitCard.filter(item => !input.data['Debit Card'].includes(item.name));

            creditList = creditList.map(val => {
              const findDuplicateInDebit = debitList.find(item => item.name === val.name);
              if (!!findDuplicateInDebit) {
                findDuplicateInDebit.name += ' (Debit)';
                val.name += ' (Credit)';
              }
              return val;
            });

            return [
              ...creditList,
              ...debitList,
            ].map(item => ({ name: item.name, percentage: item.usage })).sort((a, b) => a.percentage > b.percentage ? -1 : 1);
          })(),
          // topUnsupportedCardSchemes: [
          //   ...sourceCountryCardSchemes.creditCard.filter(item => !input.data['Credit Card'].includes(item.name))
          //     .map(val => {
          //       if (val.name === 'Mastercard') val.name = 'Mastercard (Credit)';
          //       if (val.name === 'Visa') val.name = 'Visa (Credit)';
          //       return val;
          //     })
          //   ,
          //   ...sourceCountryCardSchemes.debitCard.filter(item => !input.data['Debit Card'].includes(item.name))
          //     .map(val => {
          //       if (val.name === 'Mastercard') val.name = 'Mastercard (Debit)';
          //       if (val.name === 'Visa') val.name = 'Visa (Debit)';
          //       return val;
          //     })
          // ].map(item => ({
          //   name: item.name,
          //   percentage: item.usage
          // })).sort((a, b) => a.percentage > b.percentage ? -1 : 1),
        },
        countrySnapshot: {
          total: VarHelper.formatMoneyMilion(Math.floor(sourceCountry.snapshotValue * currency.rate), `${currency.symbol}`),
          totalNumber: sourceCountry.snapshotValue * currency.rate,
          percentage: listAPMsSourceCountry.length === 0 ? 0 : listAPMsSourceCountry.reduce((a, b) => ({ ...a, sum: a.sum + b.sum })).sum,
          mostPopular: (() => {
            const percentageAPM = listAPMsSourceCountry.length === 0 ? 0 : listAPMsSourceCountry.reduce((a, b) => ({ ...a, sum: a.sum + b.sum })).sum;
            const creditCard = sourceCountry.data.filter(val => val.name === 'Credit card')[0];
            const debitCard = sourceCountry.data.filter(val => val.name === 'Debit card')[0];
            return [
              { name: 'APMs', value: percentageAPM },
              { name: 'Credit Card', value: !creditCard ? 0 : creditCard.sum },
              { name: 'Debit Card', value: !debitCard ? 0 : debitCard.sum },
            ].sort((a,b) => a.value > b.value ? -1 : 1)[0].name;
          })(),
        },
        commerceGrowth: (() => {
          const sourceCountryCommerceGrowth = sourceData['1.2 Market growth'].find(val => val.country === countryName);
          if (!sourceCountryCommerceGrowth) return [];
          const mCommerce = sourceCountryCommerceGrowth.data.find(val => val.name === 'M-commerce');
          const eCommerce = sourceCountryCommerceGrowth.data.find(val => val.name === 'E-commerce');
          if (!mCommerce || !eCommerce) return [];
          return [
            { year: 2018, eCommerce: eCommerce.size2018, mCommerce: mCommerce.size2018 },
            { year: 2019, eCommerce: eCommerce.size2019, mCommerce: mCommerce.size2019 },
            { year: 2020, eCommerce: eCommerce.size2020, mCommerce: mCommerce.size2020 },
            { year: 2021, eCommerce: eCommerce.size2021, mCommerce: mCommerce.size2021 },
            { year: 2022, eCommerce: eCommerce.size2022, mCommerce: mCommerce.size2022 },
          ]
        })(),
        commerceGrowthPercentage: (() => {
          const sourceCountryCommerceGrowth = sourceData['1.2 Market growth'].find(val => val.country === countryName);
          if (!sourceCountryCommerceGrowth) return { eCommerce: 0, mCommerce: 0 };
          const mCommerce = sourceCountryCommerceGrowth.data.find(val => val.name === 'Ecommerce % growth');
          const eCommerce = sourceCountryCommerceGrowth.data.find(val => val.name === 'M-commerce % growth');
          return {
            eCommerce: !eCommerce ? 0 : eCommerce.growth20182022,
            mCommerce: !mCommerce ? 0 : mCommerce.growth20182022,
          }
        })(),
        inStore: (() => {
          const sourceCountryInStore = sourceData['2.1 Instore payment split'].find(val => val.country === countryName);
          if (!sourceCountryInStore) return [];
          return sourceCountryInStore.data;
        })(),
      };

    }

    console.log('reportData', reportData);

    localStorage.setItem('data', JSON.stringify({
      inputData,
      sourceData,
      reportData,
    }));
  }

}

export default new ReportManager();