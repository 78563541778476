import classes from './CreditCardAndPaymentMethods.module.scss';
import cs from 'classnames';
import { ICardDataSingleCountry } from 'type';
import Scroll from '../scroll/Scroll2';

interface ICreditCardAndPaymentMethodsProps {
  data?: {
    revenue: number,
    data: {
      [type: string]: Array<string>,
    },
    [any : string]: any,
  },
  onChange(newData : any): void,
  cardData?: ICardDataSingleCountry,
  className?: string,
}

const CreditCardAndPaymentMethods = (props : ICreditCardAndPaymentMethodsProps) => {
  const columns = [
    {
      name: 'Credit Card',
      data: !props.cardData ? [] : props.cardData.creditCard.map(val => val.name),
    },
    {
      name: 'Debit Card',
      data: !props.cardData ? [] : props.cardData.debitCard.map(val => val.name),
    },
    {
      name: 'Alternative Payment Methods',
      data: !props.cardData ? [] : props.cardData.paymenyMethods.map(val => val.name),
    },
  ];

  const toggle = (name : string, item : string) => {
    const newData = Object.assign({}, props.data?.data);
    newData[name] = !!newData[name] && newData[name].length > 0 ? newData[name] : [];
    if (newData[name].includes(item)) newData[name] = newData[name].filter(val => val !== item);
    else newData[name].push(item);
    props.onChange({ data: newData });
  };

  const sortAndMakeSureOtherInLast = (arr : Array<string>) => {
    const withoutOther = arr.filter(val => val !== 'Other' && val !== 'Others');
    return [
      ...withoutOther.sort(),
      arr.includes('Other') ? 'Other' : '',
      arr.includes('Others') ? 'Others' : '',
    ].filter(val => !!val);
  }

  return (
    <div className={cs(classes.container, props.className)}>
      {columns.map((c, i) => (
        <div key={'col-'+i}>
          <div>
            {/* CHANGE NAME */}
            <p>{c.name === 'Alternative Payment Methods' ? 'Other' : c.name}</p>
            <Scroll>
              <ul>
                {sortAndMakeSureOtherInLast(c.data).map((li, liIndex) => {
                  const isSelected = (() => {
                    if (!props.data || !props.data.data) return false;
                    if (!props.data.data[c.name]) return false;
                    if (!props.data.data[c.name].includes(li)) return false;
                    return true;
                  })();
                  return (
                    <li
                      key={c.name + liIndex}
                      className={cs(isSelected && classes.selected)}
                      onClick={() => toggle(c.name, li)}
                    >
                      {li}
                    </li>
                  );
                })}
              </ul>
            </Scroll>
          </div>
        </div>
      ))}
    </div>
  )
};

export default CreditCardAndPaymentMethods;