import {
  FullSizeContainer, Logo, AbsoluteFillContainer, Select, SelectCountries,
  EnterDetails, CurrentSelection, CreditCardAndPaymentMethods, Dialog,
  Video,
} from 'components';
import { COLOR, ASSETS, SVG, useListCurrencies, LIST_COUNTRIES } from 'const';
import { useState, useEffect, useMemo, useRef } from 'react';
import utilClasses from '../Utils.module.scss';
import classes from './Home.module.scss';
import cs from 'classnames';
import { IDataSource, ICardData, ICountryInputData as ICountryData } from 'type';
import ReportManager from './ReportManager';
import cls from 'classnames';
import isMobile from 'ismobilejs';
import './form.scss';

const Home = () => {
  const [didSubmitForm, setDidSubmitForm] = useState(!!localStorage.getItem('did_submit_form'));
  const [allowContact, setAllowContact] = useState(false);
  const [companyType, setCompanyType] = useState<{ label: string, value: string }>();
  const [country, setCountry] = useState<{ label: string, value: string }>();
  const formRef = useRef<any>(null);
  const mktoFormRef = useRef<any>(null);
  const [dialogState, setDialogState] = useState({
    open: false,
    message: 'Please complete your details before you continue.',
  })

  useEffect(() => {
    if (didSubmitForm) return;
    // @ts-ignore
    window.MktoForms2.loadForm("//go.aciworldwide.com", "030-ROK-804", 5975);
    // @ts-ignore
    window. MktoForms2.whenReady(function (form) {
      console.log('form', form);
      // @ts-ignore
      window.__form = form;
      mktoFormRef.current = form;
      setTimeout(() => {
        // @ts-ignore
        const $ = window.$
        $('#FirstName').val('');
        $('#LastName').val('');
        $('#Email').val('');
        $('#Title').val('');
        $('#Company').val('');
        $('#selfIdentifiedSegment').val('');
        $('#Phone').val('');
        $('#Country').val('');
        $('.mktoButtonRow button').text('Continue');
      }, 2000);
      form.onSuccess(function () {
        localStorage.setItem('did_submit_form', '1');
        window.location.pathname = '/select-country';
        return false;
      });
    });
  }, [didSubmitForm]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    console.log(formRef.current);
    const formData = {
      name: formRef.current.name.value,
      lastName: formRef.current.lastName.value,
      email: formRef.current.email.value,
      jobTitle: formRef.current.jobTitle.value,
      company: formRef.current.company.value,
      companyType: companyType?.value,
      telephone: formRef.current.telephone.value,
      country: country?.value,
      allowContact,
      whatAreYouInterestedIn: formRef.current['what-are-you-interested-in']?.value,
    }
    console.log('formData', formData);
    if (
      !formData.name ||
      !formData.lastName ||
      !formData.email ||
      !formData.jobTitle ||
      !formData.company ||
      !formData.companyType ||
      !formData.telephone ||
      !formData.country
    ) {
      return setDialogState({
        message: 'Please complete your details before you continue.',
        open: true,
      })
    }

    if (allowContact && !formData.whatAreYouInterestedIn) {
      return setDialogState({
        message: 'Please complete your details before you continue.',
        open: true,
      })
    }

    const mktoForm : any = document.querySelector('#mktoForm_5975');
    // @ts-ignore
    const $ = window.$
    $('#FirstName').val(formData.name);
    $('#LastName').val(formData.lastName);
    $('#Email').val(formData.email);
    $('#Title').val(formData.jobTitle);
    $('#Company').val(formData.company);
    $('#selfIdentifiedSegment').val(formData.companyType);
    $('#Phone').val(formData.telephone);
    $('#Country').val(formData.country);
    $('#salesToContact').val(formData.allowContact ? 'yes' : 'no');
    $('#salesToContact').prop('checked', formData.allowContact);
    $('#privacyandTerms').prop('checked', true);
    // mktoForm['LastName'].value = formData.lastName;
    // mktoForm['Email'].value = formData.email;
    // mktoForm['Title'].value = formData.jobTitle;
    // mktoForm['Company'].value = formData.company;
    // mktoForm['selfIdentifiedSegment'].value = formData.companyType;
    // mktoForm['Phone'].value = formData.telephone;
    // mktoForm['Country'].value = formData.country;
    // mktoForm['salesToContact'].value = formData.allowContact ? 'yes' : 'no';
    // mktoForm['salesToContact'].checked = formData.allowContact;
    // mktoForm['privacyandTerms'].checked = true;

    mktoFormRef.current.onSubmit(function() {
      console.log('onSubmit');
    })
    mktoFormRef.current.submit();
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const device = isMobile(userAgent);
    const isInIframe = window.location !== window.parent.location;
    if (!!(device.phone || device.tablet) && !isInIframe) {
      window.location.pathname = '/responsive-1';
    }
  }, []);

  return (
    <>
      <FullSizeContainer bgColor={"#2D2BE0"}>
        <AbsoluteFillContainer middle>
          <Video
            className={cls(utilClasses.img_fullwidth, classes.headerVideo)}
            controls={false}
            muted={true}
            autoPlay
            loop
            playsInline
            srcs={[
              { src: ASSETS.ALPHA_MOV_VIDEO_HEADER_MAIN, type: 'video/quicktime', device: 'safari' },
              { src: ASSETS.ALPHA_WEBM_VIDEO_HEADER_MAIN, type: 'video/webm', device: 'chrome, firefox' },
            ]}
          >
            <source src={ASSETS.ALPHA_MOV_VIDEO_HEADER_MAIN} type="video/quicktime" />
            {/* <source src="https://rotato.netlify.app/alpha-demo/movie-hevc.mov" type="video/quicktime" /> */}
            <source src={ASSETS.ALPHA_WEBM_VIDEO_HEADER_MAIN} type="video/webm" />
          </Video>
          <h3 className={classes.heading}>Global Commerce Trends Calculator</h3>
          <div className={classes.start}>
            <span>Start</span>
            {/* <img src={ASSETS.START_CURSOR} onClick={() => window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })} alt="Start now" /> */}
            <SVG.HomeBannerArrowAnim
              onClick={() => window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })}
            />
          </div>
        </AbsoluteFillContainer>
        <Logo />
      </FullSizeContainer>

      <FullSizeContainer
        bgColor={"#2D2BE0"}
        style={{
          backgroundImage: `url("${ASSETS.FORM_BG}")`,
          backgroundPosition: 'left bottom',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={classes.homeFormContainer}>
          <section>
            <div>
              <h3 className={classes.overview}>Overview</h3>
              <p>
                How much revenue are you potentially missing out on by not supporting the right alternative payment methods in your growth markets around the world?
                <br /><br />
                Take our interactive assessment to discover which APMs you should focus on and how much revenue uplift this could provide. You’ll also discover some local trends and growth statistics so that you can ensure your payments strategy is targeted and aligned with local payer expectations.
              </p>
            </div>
            <div className={classes.copyrightContainer}>
              <p>
                © Copyright ACI Worldwide, Inc. 2022<br />
                ACI, ACI Worldwide, ACI Payments, Inc., ACI Pay, Speedpay and all ACI product/solution names are trademarks or registered trademarks of ACI Worldwide, Inc.,
                or one of its subsidiaries, in the United States, other countries or both. Other parties’ trademarks referenced are the property of their respective owners.
              </p>
            </div>
          </section>
          <section>
            <div>
              <p><SVG.DownArrow /> Please Enter Your Details</p>
              {/* <form ref={formRef} onSubmit={onFormSubmit}>
                <div className={classes.twoColumns}>
                  <div>
                    <label>Name</label>
                    <input name="name" />
                  </div>
                  <div>
                    <label>Last Name</label>
                    <input name="lastName" />
                  </div>
                </div>
                <div className={classes.twoColumns}>
                  <div>
                    <label>Email</label>
                    <input name="email" />
                  </div>
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Job title</label>
                    <input name="jobTitle" />
                  </div>
                  <div />
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Company</label>
                    <input name="company" />
                  </div>
                  <div />
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Company Type</label>
                    <Select
                      value={companyType}
                      onChange={(data: any) => setCompanyType(data)}
                      options={[
                        { label: 'Accounts Receivable Management', value: 'Accounts Receivable Management' },
                        { label: 'Bank', value: 'Bank' },
                        { label: 'Merchant', value: 'Merchant' },
                        { label: 'Processor', value: 'Processor' },
                        { label: 'Consumer Finance', value: 'Consumer Finance' },
                        { label: 'Healthcare', value: 'Healthcare' },
                        { label: 'Insurance', value: 'Insurance' },
                        { label: 'Education', value: 'Education' },
                        { label: 'Government', value: 'Government' },
                        { label: 'Utility', value: 'Utility' },
                      ]}
                      styles={{
                        container: {
                          marginTop: 5,
                        },
                        control: {
                          borderRadius: 10,
                          border: '0 !important',
                          // This line disable the blue border
                          boxShadow: '0 !important',
                          '&:hover': {
                            border: '0 !important'
                          }
                        },
                        dropdownIndicator: {
                          color: '#2680DA',
                        }
                      }}
                      fontColor='#132744'
                    />
                  </div>
                  <div />
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Telephone</label>
                    <input name="telephone" />
                  </div>
                  <div />
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Country</label>
                    <Select
                      options={LIST_COUNTRIES.map(val => ({ label: val.name, value: val.name }))}
                      value={country}
                      onChange={(data: any) => setCountry(data)}
                      maxMenuHeight={150}
                      styles={{
                        container: {
                          marginTop: 5,
                        },
                        control: {
                          borderRadius: 10,
                          border: '0 !important',
                          // This line disable the blue border
                          boxShadow: '0 !important',
                          '&:hover': {
                            border: '0 !important'
                          }
                        },
                        dropdownIndicator: {
                          color: '#2680DA',
                        }
                      }}
                      fontColor='#132744'
                    />
                  </div>
                  <div />
                </div>
                <label className={classes.twoColumnsAnyOne}>
                  <input type="checkbox" name="allowContact" checked={allowContact} onChange={e => setAllowContact(e.target.checked)} />
                  <span>
                    I would like to be contacted by an ACI sales consultant.
                  </span>
                </label>
                {!!allowContact && (
                  <div className={classes.twoColumnsOneThird}>
                    <div>
                      <label>What are you interested in?</label>
                      <input name="what-are-you-interested-in" />
                    </div>
                    <div />
                  </div>
                )}
                <label className={classes.twoColumnsAnyOne}>
                  <input type="checkbox" name="acceptTerms" />
                  <span>
                    I accept that all personal data I submit may be processed in the manner and purposes described in ACI Worldwide’s <a href="https://www.aciworldwide.com/privacy-policy" target="_blank">Privacy Policy</a>, <a href="https://www.aciworldwide.com/terms-of-use" target="_blank">Terms of Use</a> and <a href="https://www.aciworldwide.com/privacy-policy#cookies" target="_blank">Use of Cookies</a>.
                  </span>
                </label>
                <div className={classes.btnRow}>
                  <button>Continue</button>
                </div>
              </form> */}
              {didSubmitForm ? (
                <form ref={formRef} onSubmit={e => {
                  e.preventDefault();
                  window.location.pathname = '/select-country';
                }}>
                  <label style={{ textAlign: 'center', marginBottom: 20 }}>You have already submitted your information.</label>
                  <div className={classes.btnRow}>
                    <button>Continue</button>
                  </div>
                </form>
              ) : (
                <form id="mktoForm_5975"
                  // style={{ display: 'none' }}
                />
              )}
            </div>
          </section>
        </div>
      </FullSizeContainer>
      <Dialog
        {...dialogState}
        onClose={() => {
          setDialogState(v => ({ ...v, open: false }));
          // window.location.pathname = '/select-country';
        }}
      />
      
    </>
  );
}

export default Home;