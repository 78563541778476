import {
  FullSizeContainer, Logo, AbsoluteFillContainer, Select,
  ResultComps,
} from 'components';
import { COLOR, ASSETS, HOST } from 'const';
import { useEffect, useState } from 'react';
import utilClasses from '../Utils.module.scss';
import classes from './Results.module.scss';
import cs from 'classnames';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CardSchemeChart } from 'components/results';
import { IReportData, IReportDataSingleCountry, IAllData } from 'type';

import './Results.all.css';

const Results = () => {
  const [isSingleReport] = useState(window.location.pathname.includes('/report'));
  const [data, setData] = useState<IAllData>();
  const [countryData, setCountryData] = useState<IReportDataSingleCountry>();
  const [listCountries, setListCountries] = useState<Array<{ label: string, value: string}>>([]);
  const [selectedCountry, setSelectedCountry] = useState<{ label: string, value: string}>();

  useEffect(() => {
    if (!isSingleReport) {
      const savedData = localStorage.getItem('data');
      try {
        if (!savedData) return;
        const parsedSaveData : IAllData = JSON.parse(savedData)
        setData(parsedSaveData);
        const countries = Object.keys(parsedSaveData.reportData).map(val => ({ label: val, value: val }));
        setListCountries(countries);
        setSelectedCountry(countries[0]);
        setCountryData(parsedSaveData.reportData[countries[0].value]);
      } catch(err) {}
    } else {
      const reportId = (() => {
        const parts = window.location.pathname.split('/');
        return parts[parts.length - 1];
      })();
      console.log('reportId', reportId);
      fetch(`${HOST}/report/`+reportId).then(res => res.json())
      .then(json => {
        if (Object.keys(json).length === 0) return;
        setSelectedCountry({ value: json.data.name, label: json.data.name });
        setCountryData(json.data.data);
      })
    }
  }, [isSingleReport]);

  const percentage = !countryData ? 0 : +(countryData?.overview.percentage * 100).toFixed(2);

  const listTopUnsupportedAPMs = !countryData ? [] : countryData.APMs.topUnsupported.map(val => {
    return {
      ...val,
      percentage: `${(val.percentage * 100).toFixed(2)} %`,
      value: val.revenue,
    }
  })

  const additionalUnsupportedAPMs = !countryData ? [] : countryData.APMs.additional.map(val => {
    return {
      name: val.name,
      value: `${(val.percentage * 100).toFixed(2)} %`,
    };
  });
  
  // [
  //   { name: 'Cash or cheque', value: '+5.4%' },
  //   { name: 'Prepaid card', value: '+1.7%' },
  //   { name: 'Cash on delivery', value: '+1.1%' },
  //   { name: 'Visa checkout', value: '+0.7%' },
  // ];

  const cardSchemeData = !countryData ? { support : [], unsupport: [] } : {
    support: [
      { name: 'credit card', value: `${(countryData.cardSchemes.creditCardPercentage * 100).toFixed(0)} %` },
      { name: 'debit card', value: `${(countryData.cardSchemes.debitCardPercentage * 100).toFixed(0)} %` },
    ],
    unsupport: countryData.cardSchemes.topUnsupportedCardSchemes.map(val => ({
      name: val.name,
      value: `${(val.percentage * 100).toFixed(2)} %`,
    }))
  };

  const { nextCountry, nextCountryPress } = (() => {
    if (!countryData || listCountries.length <= 1) return {
      nextCountry: '',
      nextCountryPress: () => {},
    }
    const currentIndex = listCountries.findIndex(val => val.value === selectedCountry?.value);
    const nextIndex = currentIndex + 1 === listCountries.length ? 0 : currentIndex + 1;
    const nextCountry = listCountries[nextIndex].label;
    const nextCountryPress : any = () => {
      setSelectedCountry(listCountries[nextIndex]);
      setCountryData(data?.reportData[listCountries[nextIndex].value]);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    return { nextCountry, nextCountryPress }
  })();
  
  // {
  //   support: [
  //     { name: 'credit card', value: '64.6%' },
  //     { name: 'debit card', value: '61.6%' },
  //   ],
  //   unsupport: [
  //     { name: 'Mastercard (Debit)', value: '+35.4%' },
  //     { name: 'Mastercard (Credit)', value: '+13.6%' },
  //     { name: 'American Express', value: '+11.4%' },
  //     { name: 'Tarjeta Naranja ', value: '+9.4%' },
  //   ],
  // };

  const getReport = async () => {
    const res = await fetch(`${HOST}/get-report-id`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          name: selectedCountry?.value,
          data: countryData,
        },
      }),
    })
    const json = await res.json();
    window.open(`${HOST}/report/pdf/${json.id}`, '_blank');
  }

  const renderBottom = (scrollPosition: number, decorateColor: string, darkArrow = false) => {
    return (
      <>
        {!isSingleReport &&
        <div className={classes.start}>
          <img src={darkArrow ? ASSETS.DOUBLE_ARROW_DOWN_DARK : ASSETS.DOUBLE_ARROW_DOWN} onClick={() => window.scrollTo({ top: scrollPosition, left: 0, behavior: 'smooth' })} alt="Start now" />
        </div>}
        {!!decorateColor && (
          <div className={classes.decorateBottom} style={{ backgroundColor: decorateColor }} />
        )}
      </>
    )
  };

  if (!countryData) {
    return (
      <FullSizeContainer bgColor={COLOR.DARK}>
        <AbsoluteFillContainer middle>
        <h3 className={classes.heading}>Loading...</h3>
        </AbsoluteFillContainer>
      </FullSizeContainer>
    )
  }

  return (
    <>
      <FullSizeContainer bgColor={COLOR.DARK}>
        <AbsoluteFillContainer middle>
          <img src={ASSETS.RESULTS_DART_WORK} className={utilClasses.img_fullwidth} alt="ACI Calculator" />
          <h3 className={classes.heading}>Here’s Your Report for {selectedCountry?.label}</h3>
          {!isSingleReport &&
          <div className={classes.countryDropdownContainer}>
            <Select
              borderColor={COLOR.WHITE}
              fontColor={COLOR.WHITE}
              backgroundColor={'transparent'}
              options={listCountries}
              value={selectedCountry}
              onChange={(newCountry : any) => {
                setSelectedCountry(newCountry);
                setCountryData(data?.reportData[newCountry.value]);
              }}
            />
          </div>}
        </AbsoluteFillContainer>
        {renderBottom(window.innerHeight, COLOR.MAIN)}
        <Logo />

      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.MAIN}>
        <div className={classes.overviewRow1}>
          <h3 className={classes.heading}>Overview</h3>
          <img src={ASSETS.RESULTS_OVERVIEW_ARTWORK} />
        </div>
        <div className={classes.overviewRow2}>
          <div>
            <div style={{ width: 200 }}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage} %`}
                styles={buildStyles({
                  strokeLinecap: 'round',
                  textSize: '16px',
                  pathTransitionDuration: 0.5,
                  pathColor: `rgba(38, 215, 218)`,
                  textColor: '#fff',
                  trailColor: '#575A65',
                  backgroundColor: 'transparent',
                })}

              />
            </div>
            <p>You’re supporting {percentage}% of potential eCommerce transactions with your currently supported payment methods and card schemes.</p>
          </div>
          <div>
            <div className={classes.infoBox}>
              <div>
                <p>Potential revenue uplift by supporting all available APMs:</p>
              </div>
              <div>
                <span>+{countryData.overview.revenue}</span>
              </div>
            </div>
          </div>
        </div>
        {renderBottom(window.innerHeight * 2, COLOR.WHITE)}
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.WHITE}>
        <div className={classes.APMRow1}>
          <h3 className={classes.heading}>APMS</h3>
          <img src={ASSETS.RESULTS_APMS_ARTWORK} />
        </div>
        <p className={cs(classes.APMRow2, classes.APMText)}>Your top unsupported APMs:</p>
        <div className={classes.APMRow3}>
          {listTopUnsupportedAPMs.map((val, i) => (
            <ResultComps.UnsupportedAPMColumn
              key={i}
              name={val.name}
              rank={val.rank}
              percentage={val.percentage}
              value={val.value}
            />
          ))}
        </div>
        <div className={classes.APMRow4}>
          <div className={classes.infoBox}>
            <div>
              <p>Potential revenue uplift by supporting all available APMs:</p>
            </div>
            <div>
              <span>+{countryData.overview.revenue}</span>
            </div>
          </div>

          <div className={classes.APMAdditionInfo}>
            <p>Additional unsupported APMs include:</p>
            <div>
              {additionalUnsupportedAPMs.map((val, i) => (
                <div className={classes.additionalContainer} key={'additional-'+i}>
                  <span className={classes.additionalName}>{val.name}</span>
                  <span className={classes.additionalValue}>{val.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {renderBottom(window.innerHeight * 3, COLOR.MAIN, true)}
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.MAIN}>
        <div className={classes.overviewRow1}>
          <h3 className={classes.heading}>Card Schemes</h3>
          <img src={ASSETS.RESULTS_CARD_SCHEME_ARTWORK} />
        </div>
        <div className={classes.cardSchemeRow2}>

          <CardSchemeChart
            creditValue={(countryData?.cardSchemes.creditCardPercentage || 0) * 100}
            debitValue={(countryData?.cardSchemes.debitCardPercentage || 0) * 100}
          />

          <div className={classes.cardSchemeRow2Info}>
            <p>You’re currently supporting:</p>
            <div>
              {cardSchemeData.support.map((val, i) => (
                <div key={i} className={classes.item}><span>{val.value}</span> of potential {val.name} eCommerce transactions</div>
              ))}
            </div>

            <div className={classes.unsupport}>
              <p>Your top unsupported card schemes:</p>
              <div>
                <img src={ASSETS.RESULTS_CARD_LINE} />
                <div>
                  {cardSchemeData.unsupport.map((val, i) => (
                    <div className={classes.unsupportCard} key={'unsupportCard-'+i}>
                      <span className={classes.unsupportCardName}>{val.name}</span>
                      <span className={classes.unsupportCardValue}>{val.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
          </div>
        </div>
        {renderBottom(window.innerHeight * 4, COLOR.WHITE)}
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.WHITE}>
        <AbsoluteFillContainer middle>
          <ResultComps.CountrySnapshot
            total={countryData.countrySnapshot.total}
            country={selectedCountry?.label || ''}
            mostPopular={countryData.countrySnapshot.mostPopular}
            percentage={+(countryData.countrySnapshot.percentage * 100).toFixed(2)}
          />
        </AbsoluteFillContainer>
        {renderBottom(window.innerHeight * 5, COLOR.MAIN, true)}
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.MAIN}>
        <div className={classes.commerceGrowRow1}>
          <h3 className={classes.heading}>Commerce Growth 2018-2022</h3>
        </div>
        {/* <img src={ASSETS.RESULTS_CHART_DEMO} className={classes.chartDemo} alt="" /> */}
        <ResultComps.CommerceGrowthChart
          data={countryData.commerceGrowth}
          growthPercentage={countryData.commerceGrowthPercentage}
        />
        {renderBottom(window.innerHeight * 6, COLOR.WHITE)}
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.WHITE}>
        <div className={classes.instoreRow1}>
          <h3 className={classes.heading}>In Store</h3>
          <p>Percentage share of payment methods in store for 2020</p>
          {/* <img src={ASSETS.RESULTS_CHART_DEMO_2} className={classes.chartDemo2} alt="" /> */}
          <ResultComps.InStoreChart
            data={countryData.inStore}
          />
        </div>
        {!isSingleReport && renderBottom(window.innerHeight * 7, COLOR.MAIN, true)}
      </FullSizeContainer>

      {!isSingleReport &&
      <FullSizeContainer bgColor={COLOR.MAIN}>
        <div className={classes.nextStep1}>
          <h3 className={classes.heading}>Next Steps</h3>
        </div>            
        <div className={classes.nextStep2}>
          <div className={classes.nextStep2Left}>
            <p>Thank you for interacting with our calculator tool. To find out more and discuss your requirements get in touch with our team.</p>
            <button className={classes.btn} onClick={getReport}>Get Report</button>
          </div>
          <div className={classes.nextStep2Right}>
            <img src={ASSETS.RESULTS_NEXT_STEP} alt="" />
            <button className={classes.btn}>Read our blogs</button>
          </div>

          <img src={ASSETS.RESULTS_NEXT_STEP_ARTWORK} alt="" className={classes.nextStepArtwork} />
        </div>
        {nextCountry &&
        <div className={classes.nextStep3} onClick={nextCountryPress}>
           <img src={ASSETS.RESULTS_NEXT_ARROW} alt="Next" />
           <div>
             <p>Next</p>
             <p>{nextCountry}</p>
           </div>
        </div>}
      </FullSizeContainer>}
    </>
  );
}

export default Results;