import Chart from "react-google-charts";

interface IProps {
  creditValue: number,
  debitValue: number,
}

export const CardSchemeChart = ({ creditValue, debitValue } : IProps) => {
  console.log('creditValue', creditValue);
  console.log('debitValue', debitValue);

  const round = (num : number) => {
    if (typeof num !== 'number') return 0;
    if (num > 99.99999) return 100;
    return num;
  }

  return (
    <div>
      <Chart
        width={400}
        height={300}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        // data={[
        //   ['Card 1', 'Credit', 'Debit'],
        //   ['Cards', round(creditValue), round(debitValue)],
        // ]}
        data={[
          ['Cards', 'Percentage', { role: 'style' }],
          ['Credit', round(creditValue), '#26D7DA'],
          ['Debit', round(debitValue), '#2680DA'],
        ]}
        options={{
          backgroundColor: 'transparent',
          chartArea: { width: '80%' },
          titleTextStyle: { color: '#ffffff' },
          bar: {
            groupWidth: 80
          },
          hAxis: {
            minValue: 0,
            titleTextStyle: { color: 'white' },
            textStyle: { color: 'white' },
          },
          vAxis: {
            title: 'Percentage %',
            titleTextStyle: { color: 'white' },
            textStyle: { color: 'white' },
            gridlines: { color: '#2749DA', },
            minorGridlines: { color: '#2749DA', },
            // gridlineColor: 'white'
          },
          legend: {
            position: 'none',
            textStyle: { color: 'white' },
          },
          baselineColor: 'white',
          
        }}
      />
    </div>
  );
};