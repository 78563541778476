const accounting = require('accounting');

class VarHelper {
  formatMoney = (number : number, symbol : string, toFixed = 0) => {
    return accounting.formatMoney(number, symbol, toFixed);
  }
  formatMoneyMilion = (number : number, symbol : string, toFixed = 0) => {
    if (number < 1) {
      return this.formatMoney(number * 1E6, symbol, toFixed);
    }
    return this.formatMoney(number, symbol, toFixed) + 'M';
  }
}

export default new VarHelper();
