import {
  FullSizeContainer, Logo, AbsoluteFillContainer, Select, SelectCountries,
  EnterDetails, CurrentSelection, CreditCardAndPaymentMethods, Dialog,
  Video,
} from 'components';
import { COLOR, ASSETS, SVG, useListCurrencies, LIST_COUNTRIES } from 'const';
import { useState, useEffect, useMemo, useRef } from 'react';
import utilClasses from '../Utils.module.scss';
import classes from './Home.module.scss';
import cs from 'classnames';
import { IDataSource, ICardData, ICountryInputData as ICountryData } from 'type';
import ReportManager from './ReportManager';
import cls from 'classnames';
import isMobile from 'ismobilejs';

const DebugVideo = () => {
  const [debugUrl, setDebugUrl] = useState('');
  const [debugType, setDebugType] = useState('');

  const debugInputRef = useRef<any>(null);

  const onDebugUpload = (e : any) => {
    const files = Array.from(e.target.files);
    if(files.length ===0) return;
    const file : any = files[0];
    var url = URL.createObjectURL(file);
    setDebugUrl(url);
    setDebugType(file.type);
  };

  const formRef = useRef<any>(null);
  const [dialogState, setDialogState] = useState({
    open: false,
    message: 'Please complete your details before you continue.',
  })

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    setDialogState({
      message: 'Please complete your details before you continue.',
      open: true,
    })
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const device = isMobile(userAgent);
    const isInIframe = window.location !== window.parent.location;
    if (!!(device.phone || device.tablet) && !isInIframe) {
      window.location.pathname = '/responsive-1';
    }
  }, []);

  return (
    <>
      <FullSizeContainer bgColor={"#2D2BE0"}>
        <AbsoluteFillContainer middle>
          <Video
            key={debugUrl+debugType}
            className={cls(utilClasses.img_fullwidth, classes.headerVideo)}
            controls={false}
            muted={true}
            autoPlay
            loop
            playsInline
            srcs={!!debugUrl && !!debugType ? [
              { src: debugUrl, type: debugType, device: '' },
            ] : [
              { src: ASSETS.ALPHA_MOV_VIDEO_HEADER_MAIN, type: 'video/quicktime', device: 'safari' },
              { src: ASSETS.ALPHA_WEBM_VIDEO_HEADER_MAIN, type: 'video/webm', device: 'chrome, firefox' },
            ]}
          />
          <h3 className={classes.heading}>Global Commerce Trends Calculator</h3>
          <div className={classes.start}>
            <span>Start</span>
            {/* <img src={ASSETS.START_CURSOR} onClick={() => window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })} alt="Start now" /> */}
            <SVG.HomeBannerArrowAnim
              onClick={() => window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })}
            />
          </div>
        </AbsoluteFillContainer>
        <Logo />
      </FullSizeContainer>

      <FullSizeContainer
        bgColor={"#2D2BE0"}
        style={{
          backgroundImage: `url("${ASSETS.FORM_BG}")`,
          backgroundPosition: 'left bottom',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={classes.homeFormContainer}>
          <section>
            <div>
              <h3 className={classes.overview}>Overview</h3>
              <p>
                How much revenue are you potentially missing out on by not supporting the right alternative payment methods in your growth markets around the world?
                <br /><br />
                Take our interactive assessment to discover which APMs you should focus on and how much revenue uplift this could provide. You’ll also discover some local trends and growth statistics so that you can ensure your payments strategy is targeted and aligned with local payer expectations.
              </p>
            </div>
            <div className={classes.copyrightContainer}>
              <p>
                © Copyright ACI Worldwide, Inc. 2022<br />
                ACI, ACI Worldwide, ACI Payments, Inc., ACI Pay, Speedpay and all ACI product/solution names are trademarks or registered trademarks of ACI Worldwide, Inc.,
                or one of its subsidiaries, in the United States, other countries or both. Other parties’ trademarks referenced are the property of their respective owners.
              </p>
            </div>
          </section>
          <section>
            <div>
              <p><SVG.DownArrow /> Please Enter Your Details</p>
              <form ref={formRef} onSubmit={onFormSubmit}>
                <div className={classes.twoColumns}>
                  <div>
                    <label>Name</label>
                    <input name="name" />
                  </div>
                  <div>
                    <label>Last Name</label>
                    <input name="lastName" />
                  </div>
                </div>
                <div className={classes.twoColumns}>
                  <div>
                    <label>Email</label>
                    <input name="email" />
                  </div>
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Job title</label>
                    <input name="jobTitle" />
                  </div>
                  <div />
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Company</label>
                    <input name="company" />
                  </div>
                  <div />
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Self Identified Segment</label>
                    {/* <input name="selfIdentifiedSegment" /> */}
                    <Select
                      options={[
                        {
                          label: 'Bank',
                          value: 'bank',
                        }
                      ]}
                      styles={{
                        container: {
                          marginTop: 5,
                        },
                        control: {
                          borderRadius: 10,
                          border: '0 !important',
                          // This line disable the blue border
                          boxShadow: '0 !important',
                          '&:hover': {
                            border: '0 !important'
                          }
                        }
                      }}
                      fontColor='#132744'
                    />
                  </div>
                  <div />
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Telephone</label>
                    <input name="telephone" />
                  </div>
                  <div />
                </div>
                <div className={classes.twoColumnsOneThird}>
                  <div>
                    <label>Country</label>
                    {/* <input name="country" /> */}
                    <Select
                      options={LIST_COUNTRIES.map(val => ({ label: val.name, value: val.name }))}
                      styles={{
                        container: {
                          marginTop: 5,
                        },
                        control: {
                          borderRadius: 10,
                          border: '0 !important',
                          // This line disable the blue border
                          boxShadow: '0 !important',
                          '&:hover': {
                            border: '0 !important'
                          }
                        }
                      }}
                      fontColor='#132744'
                    />
                  </div>
                  <div />
                </div>
                <label className={classes.twoColumnsAnyOne}>
                  <input type="checkbox" name="allowContact" />
                  <span>
                    I would like to be contacted by an ACI sales consultant.
                  </span>
                </label>
                <label className={classes.twoColumnsAnyOne}>
                  <input type="checkbox" name="acceptTerms" />
                  <span>
                  I accept that all personal data I submit may be processed in the manner and purposes described in ACI Worldwide’s Privacy Policy, Terms of Use and Use of Cookies.
                  </span>
                </label>
                <div className={classes.btnRow}>
                  <button>Continue</button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </FullSizeContainer>
      <Dialog
        {...dialogState}
        onClose={() => {
          setDialogState(v => ({ ...v, open: false }));
          window.location.pathname = '/select-country';
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: 10,
          background: 'rgba(255,255,255,0.5)',
        }}
      >
        <p style={{ margin: 0 }}>Insert video url</p>
        <input value={debugUrl} onChange={e => setDebugUrl(e.target.value)} placeholder='url' />
        <input value={debugType} onChange={e => setDebugType(e.target.value)} placeholder='type' />
        <div>
          <button onClick={() => {
            setDebugUrl('https://rotato.netlify.app/alpha-demo/movie-hevc.mov');
            setDebugType(`video/mp4; codecs='hvc1'`);
          }}>Load working Safari sample</button>
        </div>
        <div>
          <input type="file" style={{ display: 'none' }} ref={debugInputRef} onChange={onDebugUpload} />
          <button onClick={() => debugInputRef.current?.click()}>Upload local file</button>
        </div>
      </div>
    </>
  );
}

export default DebugVideo;