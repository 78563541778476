import {
  FullSizeContainer, Logo, AbsoluteFillContainer, Select, SelectCountries,
  EnterDetails, CurrentSelection, CreditCardAndPaymentMethods
} from 'components'; 
import { COLOR, ASSETS, SVG, useListCurrencies } from 'const';
import { useState, useEffect, useMemo } from 'react';
import utilClasses from '../Utils.module.scss';
import classes from './Home.module.scss';
import cs from 'classnames';
import { IDataSource, ICardData, ICountryInputData as ICountryData } from 'type';
import ReportManager from './ReportManager';
import cls from 'classnames';

const Home = () => {
  const [selectedCountries, setSelectedCountries] = useState<Array<{ name: string, isFocused: boolean }>>([]);

  const [sourceData, setSourceData] = useState<IDataSource>();

  const [countryData, setCountryData] = useState<ICountryData>({});
  const [currency, setCurrency] = useState({ cc:"USD",symbol:"$",name:"United States dollar", rate: 1 });
  const listCurrencies = useListCurrencies();
  const focusedCountry = selectedCountries.find(val => val.isFocused);

  const focusedCountryData = (() => {
    if (!focusedCountry) return undefined;
    return countryData[focusedCountry.name];
  })();

  useEffect(() => {
    fetch('https://api.aci-calculator.devserver.london/data')
    .then(res => res.json())
    .then(json => {
      if (json.success && Object.keys(json.data).length > 0) {
        setSourceData(json.data);
        console.log(json.data);
      }
    })
    .catch(err => {
      console.log(err);
    })
  }, []);

  

  const countryOptions = useMemo(() => {
    if (!sourceData || Object.keys(sourceData).length === 0) return [];
    if (!sourceData['1.1 Ecommerce split']) return [];
    return sourceData['1.1 Ecommerce split'].map(val => ({ name: val.country }));
  }, [sourceData]);

  const cardDataByCountry : ICardData = useMemo(() => {
    if (!sourceData || Object.keys(sourceData).length === 0) return {};
    if (!sourceData['2.2 ecomm card split']) return {};
    const result : ICardData = {};
    sourceData['2.2 ecomm card split'].forEach(c => {
      const findCountry = sourceData['1.1 Ecommerce split'].find(item => item.country === c.country);
      result[c.country] = {
        ...c,
        paymenyMethods: !findCountry ? [] : findCountry.data.filter(item => item.name !== 'Credit card' && item.name !== 'Debit card'),
      }
    });
    return result;
  }, [sourceData]);

  const focusedCountryCardData = useMemo(() => {
    if (!focusedCountry) return undefined;
    return cardDataByCountry[focusedCountry.name];
  }, [focusedCountry, cardDataByCountry]);

  const onChangeFocusCountryData = (obj : any) => {
    if (!focusedCountry) return;
    const newData = !focusedCountryData ? {
      revenue: 0,
      data: {
        'Credit Card': [],
        'Debit Card': [],
        'Alternative Payment Methods': [],
      },
    } : focusedCountryData;
    for (let key in obj) {
      newData[key] = obj[key];
    }
    setCountryData({
      ...countryData,
      [focusedCountry.name]: newData,
    })
  };

  const generateReport = () => {
    if (!sourceData) return alert('Please try again shortly');
    const { valid, message } = ReportManager.verifyInput(countryData);
    if (!valid) return alert(message);
    ReportManager.generateReport(countryData, sourceData, currency);
    window.location.pathname = '/results';
  };

  return (
    <>
      <FullSizeContainer bgColor={"#2D2BE0"}>
        <AbsoluteFillContainer middle>
          {/* <img src={ASSETS.HOME_ART_WORK} className={utilClasses.img_fullwidth} alt="ACI Calculator" /> */}
          <video
            src={ASSETS.VIDEO_HEADER_MAIN}
            className={cls(utilClasses.img_fullwidth, classes.headerVideo)}
            controls={false}
            muted
            autoPlay
            loop
            playsInline
          />
          <h3 className={classes.heading}>Global Commerce Trends Calculator</h3>
          <div className={classes.start}>
            <span>Start</span>
            {/* <img src={ASSETS.START_CURSOR} onClick={() => window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })} alt="Start now" /> */}
            <SVG.HomeBannerArrowAnim
              onClick={() => window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })}
            />
          </div>
        </AbsoluteFillContainer>
        <Logo />
      </FullSizeContainer>

      <FullSizeContainer bgColor={COLOR.WHITE}>
        <AbsoluteFillContainer middle>
          <div className={classes.whiteRowInfo}>
            <div>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam.</p>
            </div>
            <div>
              <img src={ASSETS.HOME_DECOR_ICON} alt="" />
            </div>
          </div>

          <div className={classes.whiteRowCurrency}>
            <p><SVG.DownArrow /> Select currency to use</p>
            <Select
              value={{
                label: `${currency.cc} - ${currency.name}`,
                value: currency.rate,
                raw: currency,
              }}
              options={listCurrencies.map(val => ({
                label: `${val.cc} - ${val.name}`,
                value: val.rate,
                raw: val,
              }))}
              // @ts-ignore
              onChange={newCurrency => setCurrency(newCurrency.raw)}
              borderColor="var(--color-second)"
              fontColor="var(--color-main)"
            />
          </div>
        </AbsoluteFillContainer>
      </FullSizeContainer>

      {window.innerWidth <= 768 ? (
        <FullSizeContainer bgColor={COLOR.DARK}>
          <AbsoluteFillContainer middle>
            <h3 className={classes.selectCountriesInstruction}>Please use Desktop to view this section </h3>
          </AbsoluteFillContainer>
        </FullSizeContainer>
      ) : (
        <FullSizeContainer bgColor={COLOR.DARK}>
          <h3 className={classes.selectCountriesInstruction}>Select up to 5 Countries You Currently Operate in</h3>

          <div className={classes.selectCountriesRow}>
            <div className={cs(classes.selectCountriesRowLeft, !!focusedCountry && classes.full5)}>
              <SelectCountries
                data={selectedCountries}
                onChange={setSelectedCountries}
                listCountries={countryOptions}
              />
              {!!focusedCountry ? (
                <>
                  <EnterDetails data={focusedCountryData} onChange={onChangeFocusCountryData} currency={currency} />
                  <CreditCardAndPaymentMethods
                    data={focusedCountryData}
                    onChange={onChangeFocusCountryData}
                    cardData={focusedCountryCardData}
                  />
                </>
              ) : (
                <div style={{ flex: 4 }} />
              )}
            </div>
            <div className={classes.selectCountriesRowRight}>
              <CurrentSelection
                data={selectedCountries}
                onChange={setSelectedCountries}
              />

              <button
                className={classes.generateReport}
                // onClick={() => window.location.pathname = '/results'}
                onClick={generateReport}
              >Generate Report</button>
            </div>
          </div>
          
          <img src={ASSETS.HOME_DECOR_2} className={classes.decor_bottom} alt="" />
        </FullSizeContainer>
      )}
    </>
  );
}

export default Home;