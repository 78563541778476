import Scrollbars from 'react-scrollbars-custom';
import classes from './Scroll.module.scss';
import cs from 'classnames';

const Scroll2 = ({ children } : any) => {
  return (
    <Scrollbars
      style={{ width: '100%', height: '100%' }}
      trackYProps={{ className: classes.trackY }}
      thumbYProps={{ className: classes.thumbY }}
    >
      {children}
      <div style={{ height: 20 }} />
    </Scrollbars>
  );
};

export default Scroll2;