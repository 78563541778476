import classes from './AbsoluteFillContainer.module.scss';
import cs from 'classnames';
import { CSSProperties } from 'react';

interface IAbsoluteFillContainerProps {
  middle?: boolean,
  children?: any,
  style?: CSSProperties,
}

const AbsoluteFillContainer = ({ middle, children, style } : IAbsoluteFillContainerProps) => {
  return (
    <div className={cs(classes.container, middle && classes.middle)} style={style}>
      {children}
    </div>
  );
};

export default AbsoluteFillContainer;

