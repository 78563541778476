import { COLOR } from "const";
import Chart from "react-google-charts";

interface IProps {
  data: Array<{
    name: string,
    value: number,
  }>,
}

export const InStoreChart = (props: IProps) => {
  const chartData = [
    ['In Store', 'Percentage', { role: 'style' }, {type: 'string', role: 'tooltip', p: {html: true}}],
    ...props.data.map((val, index) => [
      val.name,
      val.value * 100,
      index % 2 === 0 ? '#2726DA' : '#26D7DA',
      `<div style="padding:5px;">
        <h4 style="margin: 0px; font-weight: bold; font-size: 16px; text-stroke: 1px black;">${val.name}</h4>
        Percentage: <strong style="font-weight: bold; font-size: 14px;">${Math.floor(val.value * 100)} %</strong>
      </div>`,
    ]),
  ];
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Chart
        width={window.innerWidth * 0.9}
        height={500}
        chartType="ColumnChart"
        loader={<div style={{ color: 'white' }}>Loading Chart</div>}
        data={chartData}
        options={{
          vAxis: {
            position: 'left',
            title: 'Percentage',
            titleTextStyle: { color: '#575A65' },
            // textStyle: { color: 'white' },
          },
          hAxis: {
            titleTextStyle: { color: '#575A65' },
            textStyle: { color: '#575A65' },
          },
          series: {
            1: { curveType: 'function' },
          },
          backgroundColor: 'transparent',
          colors: props.data.map((val, i) => i % 2 === 0 ? '#2726DA' : '#26D7DA'),
          chartArea: { width: '70%' },
          // titleTextStyle: { color: '#ffffff' },
          legend: {
            position: 'none',
            // textStyle: { color: 'white' },
          },
          tooltip: { isHtml: true, trigger: "visible" },
        }}
        rootProps={{ 'data-testid': '2' }}
      />
    </div>
  );
};
