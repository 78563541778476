import { useEffect, useState } from 'react';
import classes from './Dialog.module.scss';

interface IDialogProps {
  open: boolean;
  onClose(): void;
  message: string;
}

const Dialog = (props : IDialogProps) => {
  const isInIframe = window.location !== window.parent.location;

  useEffect(() => {
    if (!isInIframe) return;
    if (props.open) {
      window.parent.postMessage('iframeMessage='+props.message, '*');
      setTimeout(() => {
        props.onClose();
      }, 500);
    }
  }, [props.open, isInIframe]);
  if (isInIframe) return null;
  if (!props.open) return null;
  return (
    <div className={classes.container}>
      <div className={classes.dialog}>
        <div className={classes.dialogHeader}>

        </div>
        <div className={classes.dialogBody}>
          <p>{props.message}</p>
          <button onClick={props.onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;