import { Scrollbars } from 'react-custom-scrollbars';
import { LIST_COUNTRIES, SVG } from 'const';
import classes from './SelectCountries.module.scss';
import cs from 'classnames';




interface ISelectCountriesProps {
  data: Array<{ name: string, isFocused: boolean }>,
  onChange?(newData : Array<{ name: string, isFocused: boolean }>): void,
  listCountries?: Array<{ name: string }>,
}

const MAX_SELECTED = 5;

const SelectCountries = ({ data, onChange, listCountries } : ISelectCountriesProps) => {

  const countries = listCountries && listCountries.length > 0 ? listCountries : LIST_COUNTRIES;

  const toggleCountry = (val : { name : string, isFocused: boolean }, isSelected : boolean) => {
    // console.log(val, isSelected);
    // if (!isSelected && data.length >= MAX_SELECTED) return;
    // if (!isSelected && data.length < MAX_SELECTED) {
    //   !!onChange && onChange([...data.map(item => ({ name: item.name, isFocused: false })), { name: val.name, isFocused: true }]);
    //   return;
    // }
    // if (isSelected && !val.isFocused) {
    //   const index = data.findIndex(item => item.name === val.name);
    //   !!onChange && onChange([
    //     ...data.slice(0, index),
    //     { name : val.name, isFocused: true },
    //     ...data.slice(index + 1, data.length),
    //   ]);
    // }

    if (!isSelected && data.length >= MAX_SELECTED) return;
    if (!isSelected && data.length < MAX_SELECTED) {
      !!onChange && onChange([
        ...data.map(item => ({ name: item.name, isFocused: false })),
        { name: val.name, isFocused: true },
      ]);
    } else if (isSelected && !val.isFocused) {
      const index = data.findIndex(item => item.name === val.name);
      const newData = data.slice().map(item => ({ name: item.name, isFocused: false }));
      newData[index].isFocused = true;
      !!onChange && onChange(newData);
    }
  }

  return (
    <div className={classes.container}>
      <Scrollbars
        style={{ width: '100%', height: '100%' }}
        renderThumbVertical={({ props }) =>
          <div {...props} className={classes.thumbScrollVertical} />
        }
        renderTrackVertical={props => <div {...props} className={classes.trackScrollVertical} style={{ width: 10 }} />}
      >
        {countries.map((val, i) => {
          const findItem = data.find(item => item.name === val.name);
          const isSelected = !!findItem;
          const isFocused = !!findItem && !!findItem.isFocused;
          return (
            <div
              key={'countries_'+val.name} className={cs(classes.country_item, isFocused && classes.selected)}
              onClick={() => toggleCountry(findItem || { name: val.name, isFocused: false }, isSelected)}
            >
              <span>
                {val.name}
              </span>
            </div>
          );
        })}
      </Scrollbars>
      <div className={classes.instructionText}>
        <SVG.DownArrow />
        Select countries
      </div>
    </div>
  );
};

export default SelectCountries;