import React, { useState, useRef, useEffect } from 'react';
import { FullSizeContainer, RatioContainer, useRatioDimensions, Dialog } from 'components';
import { COLOR } from 'const';

const Responsive1 = () => {
  const width = useRatioDimensions(1920/1080);
  const scale = width/1920;
  const translate = 0;
  const [bgColor, setBgColor] = useState('#2D2BE0');
  const iframeRef = React.createRef<HTMLIFrameElement>();
  const [dialogState, setDialogState] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    window.addEventListener('message', function (e) {
      // Get the sent data
      const data = String(e.data);
      if (!!data && data.includes('iframeMessage=')) {
        const message = data.replace('iframeMessage=', '');
        setDialogState({
          open: true,
          message,
        })
      }
  });
  }, []);

  return (
    <div style={{ height: '100%', width: '100%', padding: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: bgColor }} >
      <RatioContainer width={width} ratio={1920/1080}>
        <iframe
          src='/home'
          style={{
            position: 'absolute',
            width: 1920,
            height: 1080,
            border: 'none',
            transform: `scale(${scale}) translate(${translate}%, ${translate}%)`,
            transformOrigin: 'top left',
          }}
          ref={iframeRef}
          onLoad={(e) => {
            // console.log('contentWindow?.location', iframeRef.current?.contentWindow?.location?.pathname);
            const pathname = iframeRef.current?.contentWindow?.location?.pathname;
            if (!pathname) return;
            switch (pathname) {
              case '/home': setBgColor('#2D2BE0'); break;
              case '/select-country': setBgColor('#152C50'); break;
              case '/input-country-data': setBgColor('#132744'); break;
              case '/select-report': setBgColor(COLOR.DARK); break;
              case '/view-single-report': setBgColor('#122B4E'); break;
              default: setBgColor(COLOR.DARK);
            }
          }}
        />
      </RatioContainer>
      <Dialog
        {...dialogState}
        onClose={() => {
          setDialogState(v => ({ ...v, open: false }));
        }}
      />
    </div>
  );
};

export default Responsive1;
