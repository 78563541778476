import React, { useState } from 'react';
import classes from './EnterDetailNewFlow.module.scss';
import { ASSETS } from 'const';
import { getCountryThumb } from '../useCountryThumb/useCountryThumb'

interface ICountryThumbProps {
  name: string,
}

const CountryThumb = ({ name } : ICountryThumbProps) => {
  // const image = (() => {
  //   const findAvailable = availableAssets.find(val => val.name === name);
  //   return !!findAvailable ? findAvailable.image : ASSETS.THUMB_RANDOM;
  // })();
  const image = getCountryThumb(name);
  return (
    <div className={classes.countryThumbContainer}>
      <div className={classes.thumb}>
        <img src={image} alt={name} />
        <div />
      </div>
      <div className={classes.headingContainer}>
        <h3 className={classes.heading}>{name}</h3>
      </div>
    </div>
  );
};

export default CountryThumb;