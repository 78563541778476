import React, { useState, useEffect } from 'react';
import {
  FullSizeContainer,
  Logo,
  AbsoluteFillContainer,
  useCountryThumb,
  getCountryThumb,
} from 'components';
import classes from './SelectReport.module.scss';
import { COLOR, ASSETS } from 'const';
import { IDataSource, ICardData, ICountryInputData as ICountryData } from 'type';

const SelectReport = () => {
  const [countryData, setCountryData] = useState<ICountryData>({});
  useEffect(() => {
    const stringCountryData = localStorage.getItem('InputCountryData_countryData');
    try {
      if (!!stringCountryData) {
        const newCountryData = JSON.parse(stringCountryData);
        setCountryData(newCountryData);
      }
    } catch(err){}
  }, []);

  const countryArray = Object.keys(countryData);

  const selectReport = (name : string) => {
    localStorage.setItem('viewReport_name', name);
    window.location.pathname = '/view-single-report';
  };

  return (
    <>
      <FullSizeContainer bgColor={COLOR.DARK}>
        <Logo />
        <div className={classes.container}>
          <h3 className={classes.heading}>Select your report to view</h3>
          <div className={classes.reportRow}>
            {countryArray.map((val, i) => {
              return (
                <div className={classes.item} key={val} onClick={() => selectReport(val)}>
                  <div className={classes.thumb}>
                    <img src={getCountryThumb(val)} />
                    <div />
                  </div>
                  <div className={classes.name}>{val}</div>
                </div>
              );
            })}
          </div>
        </div>
      </FullSizeContainer>
    </>
  );
};

export default SelectReport;
