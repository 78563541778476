import classes from './ImportData.module.scss';
import { useRef, useState } from 'react';
import {
  FullSizeContainer,
  AbsoluteFillContainer
} from 'components'; 
import { COLOR, ASSETS, SVG, LIST_COUNTRIES } from 'const';
import { IDataSource } from 'type';
import { processExcel } from './ImportData.processExcel';

const getBinary = (raw : any) => new Promise((resolve, reject) => {
  var reader = new FileReader();
  reader.onload = function (e) {
    // console.log('onload')
    // @ts-ignore
    var data = e.target.result;
    resolve(data);
  };
  reader.onerror = function (ex) {
    reject(ex);
  };
  reader.readAsBinaryString(raw as any);
});

const ImportData = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [didImport, setDidImport] = useState(false);
  const [toBeUploadedData, setToBeUploadedData] = useState<any>();
  const inputRef = useRef<any>();


  const onHandleFile = async (e : any) => {
    const file = e.target.files[0];
    if (!file) return;
    setIsProcessing(true);
    setDidImport(false);
    try {
      const binary = await getBinary(file);
      // @ts-ignore
      const workbook = new window.ExcelJS.Workbook();
      await workbook.xlsx.load(binary);
      console.log('workbook.worksheets', workbook.worksheets);
      // var worksheet = workbook.worksheets[0];
      // console.log('worksheet', worksheet);

      const data = processExcel(workbook.worksheets);
      setToBeUploadedData(data);
      importData(data);
    } catch(err) {
      alert(err);
    }

    setIsProcessing(false);

    if (inputRef.current) inputRef.current.value = '';
  };

  const importData = async (data : IDataSource) => {
    try {
      const devUrl = 'http://localhost:5000/import-data';
      const liveUrl = 'https://api.aci-calculator.devserver.london/import-data';
      const res = await fetch(liveUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      });
      const json = await res.json();
      if (json.success) setDidImport(true);
    } catch(err) {
      alert(err);
    }
  };

  return (
    <>
      <FullSizeContainer bgColor={COLOR.DARK}>
        <AbsoluteFillContainer middle>
          <input
            type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: 'none' }} ref={inputRef}
            onChange={onHandleFile}
          />
          <button className={classes.btnImport} onClick={() => inputRef.current?.click()}>
            {isProcessing ? 'Processing..' : 'Click to import data'}
          </button>
          <p className={classes.mes}>Please note that the Excel file must follow the format of <a href={ASSETS.SAMPLE_EXCEL}>this</a> sample</p>
          {Boolean(didImport) && <p className={classes.mes}>Data imported successfully</p>}
          {Boolean(toBeUploadedData) && (
            <div className={classes.previewContainer}>
              <h3>Preview JSON Data</h3>
              <pre>
                {JSON.stringify(toBeUploadedData['1.1 Ecommerce split'], undefined, 4)}
              </pre>
              {/* <ReactJson src={toBeUploadedData} /> */}
            </div>
          )}
        </AbsoluteFillContainer>
      </FullSizeContainer>
    </>
  );
};

export default ImportData;
