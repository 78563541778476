import classes from './CountrySnapshot.module.scss';
import { VarHelper } from 'helpers';
import { getCountryThumb } from 'components';

interface ICountrySnapshotProps {
  total: string,
  country: string,
  percentage: number,
  mostPopular: string,
  isForPrint?: boolean,
}

export const CountrySnapshot = (props : ICountrySnapshotProps) => {
  return (
    <div className={classes.container} style={{ height: props.isForPrint ? 470 : undefined }}>
      <div className={classes.thumb}>
        <img src={getCountryThumb(props.country)} alt="" />
        <div />
      </div>
      <div className={classes.content}>
        <div className={classes.row1}>
          <div className={classes.value}>
            <h4>{props.total}</h4>
            <p>is the total eCommerce value of {props.country}. </p>
          </div>
        </div>

        <div className={classes.row2}>
          <div>
            <p>Digital Wallets and APMs combine together to form nearly {props.percentage}% of all eCommerce transactions.</p>
          </div>
          <div>
            <p>{props.mostPopular} stays the most popular method of eCommerce payment in {props.country}.</p>
          </div>
          <div>
            <p>Mercado Pago, a local eCommerce payment method is gaining more popularity, increasing 28% in eCommerce transactions to 12.40%</p>
          </div>
        </div>
      </div>
    </div>
  )
};
