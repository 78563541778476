import ReactSelect, { Props } from 'react-select';
import { COLOR } from 'const';

interface ISelectProps extends Props {
  borderColor?: string,
  fontColor?: string,
  backgroundColor?: string,
  styles?: any,
}

const Select = (props : ISelectProps) => {
  return (
    <ReactSelect 
      {...props}
      styles={{
        container: style => ({
          ...style,
          ...props.styles?.container,
        }),
        control: (style) => ({
          ...style,
          borderWidth: 2,
          paddingBottom: 4,
          paddingLeft: 4,
          borderColor: props.borderColor ? props.borderColor : style.borderColor,
          backgroundColor: props.backgroundColor ? props.backgroundColor : style.backgroundColor,
          boxShadow: 'none',
          '&:hover': {
            borderColor: props.borderColor ? props.borderColor : style.borderColor,
          },
          ...props.styles?.control,
        }),
        input: styles => ({ ...styles, color: props.fontColor ? props.fontColor : styles.color }),
        singleValue: styles => ({
          ...styles, color: props.fontColor ? props.fontColor : styles.color, marginTop: -5 }),
        menu: (styles) => ({ ...styles, backgroundColor: props.backgroundColor ? props.backgroundColor : styles.backgroundColor, }),
        option: (style) => ({
          ...style,
          "&:hover": {
            color: ['transparent', COLOR.DARK].includes(props.backgroundColor || '') ? 'black' : undefined,
          }
        }),
        indicatorSeparator: (style) => ({ display: 'none' }),
        dropdownIndicator: (style) => ({
          ...style,
          ...props.styles?.dropdownIndicator,
        }),
      }}
    />
  )
};

export default  Select;