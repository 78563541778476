import {
  FullSizeContainer, Logo, AbsoluteFillContainer, Select, SelectCountries,
  EnterDetails, CurrentSelection, CreditCardAndPaymentMethods, Dialog,
  Video,
} from 'components';
import { COLOR, ASSETS, SVG, useListCurrencies } from 'const';
import { useState, useEffect, useMemo } from 'react';
import utilClasses from '../Utils.module.scss';
import homeclasses from './Home.module.scss';
import classes from './SelectCountry.module.scss';
import cs from 'classnames';
import { IDataSource, ICardData, ICountryInputData as ICountryData } from 'type';
import ReportManager from './ReportManager';
import cls from 'classnames';

const SelectCountry = () => {
  const [dialogState, setDialogState] = useState({
    open: false,
    message: '',
  })
  const [selectedCountries, setSelectedCountries] = useState<Array<{ name: string, isFocused: boolean }>>([]);

  const [sourceData, setSourceData] = useState<IDataSource>();

  const [currency, setCurrency] = useState({ cc: "USD", symbol: "$", name: "United States dollar", rate: 1 });
  const listCurrencies = useListCurrencies();

  useEffect(() => {
    fetch('https://api.aci-calculator.devserver.london/data')
      .then(res => res.json())
      .then(json => {
        if (json.success && Object.keys(json.data).length > 0) {
          setSourceData(json.data);
          console.log(json.data);
        }
      })
      .catch(err => {
        console.log(err);
      })
  }, []);

  const countryOptions = useMemo(() => {
    if (!sourceData || Object.keys(sourceData).length === 0) return [];
    if (!sourceData['1.1 Ecommerce split']) return [];
    return sourceData['1.1 Ecommerce split'].map(val => ({ name: val.country }));
  }, [sourceData]);

  useEffect(() => {
    const toBeSaved = selectedCountries.map(val => val.name);
    localStorage.setItem('selected_countries', JSON.stringify(toBeSaved));
  }, [selectedCountries]);

  useEffect(() => {
    localStorage.setItem('selected_currency', JSON.stringify(currency));
  }, [currency]);

  const onClickContinue = () => {
    window.location.pathname = '/input-country-data';
    localStorage.removeItem('InputCountryData_countryData');
  };

  return (
    <>
      {window.innerWidth <= 768 ? (
        <FullSizeContainer bgColor={COLOR.DARK}>
          <AbsoluteFillContainer middle>
            <h3 className={homeclasses.selectCountriesInstruction}>Please use Desktop to view this section </h3>
          </AbsoluteFillContainer>
        </FullSizeContainer>
      ) : (
        <FullSizeContainer bgColor={'#152C50'}>
          <AbsoluteFillContainer>
            <div className={classes.decor_bottom}>
              <div>
                <Video
                  controls={false}
                  muted
                  autoPlay
                  loop
                  playsInline
                  srcs={[
                    { src: ASSETS.ALPHA_MOV_VIDEO_MONUMENTS, type: 'video/quicktime', device: 'safari' },
                    { src: ASSETS.ALPHA_WEBM_VIDEO_MONUMENTS, type: 'video/webm', device: 'chrome, firefox' },
                  ]}
                />
              </div>
            </div>
          </AbsoluteFillContainer>
          <AbsoluteFillContainer>
            <div className={classes.selectCountriesRow}>
              <div className={classes.selectCurrencyContainer}>
                <p><SVG.DownArrow /> Select currency to use</p>
                <Select
                  value={{
                    label: `${currency.cc} - ${currency.name}`,
                    value: currency.rate,
                    raw: currency,
                  }}
                  options={listCurrencies.map(val => ({
                    label: `${val.cc} - ${val.name}`,
                    value: val.rate,
                    raw: val,
                  }))}
                  // @ts-ignore
                  onChange={newCurrency => setCurrency(newCurrency.raw)}
                  borderColor={COLOR.SECOND}
                  fontColor={COLOR.WHITE}
                  backgroundColor={'#152C50'}
                  styles={{
                    dropdownIndicator: {
                      display: 'none'
                    }
                  }}
                />
              </div>

              <div className={classes.selectCountryContainer}>
                <p><SVG.DownArrow /> Select Up to Five Countries</p>
                <div className={classes.listContainer}>
                  <div className={classes.list}>
                    {countryOptions.map((country, i) => {
                      const isSelected = !!selectedCountries.find(val => val.name === country.name);
                      return (
                        <div
                          className={cls(classes.item, { [classes.selected]: isSelected })}
                          key={country.name}
                          onClick={() => {
                            if (isSelected) setSelectedCountries(v => v.filter(item => item.name !== country.name))
                            else if (selectedCountries.length >= 5) {
                              setDialogState({
                                open: true,
                                message: 'A maximum of five countries may be selected.',
                              })
                            } else {
                              const newSelected = selectedCountries.slice();
                              newSelected.push({ name: country.name, isFocused: false });
                              setSelectedCountries(newSelected);
                            }
                          }}
                        >
                          {country.name}
                          {isSelected && (
                            <div className={classes.deselectItemIcon}>
                              x
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                  <button className={classes.continueBtn} onClick={onClickContinue}>Continue</button>
                </div>
              </div>
            </div>
            <div style={{ height: 200 }} />
          </AbsoluteFillContainer>


          {/* <img src={ASSETS.HOME_DECOR_2} className={classes.decor_bottom} alt="" /> */}
          

        </FullSizeContainer>
      )}
      <Dialog
        {...dialogState}
        onClose={() => {
          setDialogState(v => ({ ...v, open: false }));
        }}
      />
    </>
  );
}

export default SelectCountry;