import classes from './EnterDetails.module.scss';
import { SVG } from 'const';
interface IEnterDetailsProps {
  data?: {
    revenue: number,
    [any : string]: any,
  },
  currency: {
    cc: string,
    name: string,
    symbol: string,
  },
  onChange(newData : any): void,
}

const EnterDetails = (props : IEnterDetailsProps) => {
  return (
    <div className={classes.container}>
      <p>Enter your revenue for this country</p>
      <div className={classes.inputContainer}>
        <input
          value={props.data?.revenue || ''}
          type='number'
          onChange={(e) => {
            const value = e.target.value;
            props.onChange({
              revenue: value,
            });
          }}
        />
        <span>{props.currency.symbol}</span>
      </div>
      <p>Now select your supported payment methods</p>
      <div className={classes.instructionText}>
        <SVG.DownArrow />
        Enter Details
      </div>
    </div>
  );
};

export default EnterDetails;