import { COLOR, ASSETS, LIST_COUNTRIES } from 'const';

export const useCountryThumb = (name: string, size = 's') => {
  const findAvailable = LIST_COUNTRIES.find(val => val.name === name);
  if (findAvailable) return  size === 's' ? findAvailable.imageS : findAvailable.imageB;
  return ASSETS.THUMB_RANDOM;
}

export const getCountryThumb = (name: string, size = 's') => {
  const findAvailable = LIST_COUNTRIES.find(val => val.name === name);
  if (findAvailable) return  size === 's' ? findAvailable.imageS : findAvailable.imageB;
  return ASSETS.THUMB_RANDOM;
}

