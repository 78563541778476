import { ASSETS } from './assets';
export const LIST_PROVIDED_COUNTRIES = [
  {
    name: 'Argentina',
    imageB: require(`./country-images/Argentina-b.png`).default,
    imageS: require(`./country-images/Argentina-s.png`).default,
  },
  {
    name: 'Australia',
    imageB: require(`./country-images/Australia-b.png`).default,
    imageS: require(`./country-images/Australia-s.png`).default,
  },
  {
    name: 'Austria',
    imageB: ASSETS.THUMB_RANDOM,
    imageS: ASSETS.THUMB_RANDOM,
  },
  {
    name: 'Azerbaijan',
    imageB: ASSETS.THUMB_RANDOM,
    imageS: ASSETS.THUMB_RANDOM,
  },
  {
    name: 'Bahrain',
    imageB: ASSETS.THUMB_RANDOM,
    imageS: ASSETS.THUMB_RANDOM,
  },
  {
    name: 'Belgium',
    imageB: require(`./country-images/Belgium-b.png`).default,
    imageS: require(`./country-images/Belgium-s.png`).default,
  },
  {
    name: 'Brazil',
    imageB: require(`./country-images/Brazil-b.png`).default,
    imageS: require(`./country-images/Brazil-s.png`).default,
  },
  {
    name: 'Bulgaria',
    imageB: ASSETS.THUMB_RANDOM,
    imageS: ASSETS.THUMB_RANDOM,
  },
  {
    name: 'Canada',
    imageB: require(`./country-images/Canada-b.png`).default,
    imageS: require(`./country-images/Canada-s.png`).default,
  },
  {
    name: 'Chile',
    imageB: require(`./country-images/Chile-b.png`).default,
    imageS: require(`./country-images/Chile-s.png`).default,
  },
  {
    name: 'China',
    imageB: require(`./country-images/China-b.png`).default,
    imageS: require(`./country-images/China-s.png`).default,
  },
  {
    name: 'Colombia',
    imageB: require(`./country-images/Colombia-b.png`).default,
    imageS: require(`./country-images/Colombia-s.png`).default,
  },
  {
    name: 'Czech Republic',
    imageB: require(`./country-images/Czech-Republic-b.png`).default,
    imageS: require(`./country-images/Czech-Republic-s.png`).default,
  },
  {
    name: 'Denmark',
    imageB: require(`./country-images/Denmark-b.png`).default,
    imageS: require(`./country-images/Denmark-s.png`).default,
  },
  {
    name: 'Egypt',
    imageB: require(`./country-images/Egypt-b.png`).default,
    imageS: require(`./country-images/Egypt-s.png`).default,
  },
  {
    name: 'Estonia',
    imageB: require(`./country-images/Estonia-b.png`).default,
    imageS: require(`./country-images/Estonia-s.png`).default,
  },
  {
    name: 'Finland',
    imageB: require(`./country-images/Finland-b.png`).default,
    imageS: require(`./country-images/Finland-s.png`).default,
  },
  {
    name: 'France',
    imageB: require(`./country-images/France-b.png`).default,
    imageS: require(`./country-images/France-s.png`).default,
  },
  {
    name: 'Germany',
    imageB: require(`./country-images/Germany-b.png`).default,
    imageS: require(`./country-images/Germany-s.png`).default,
  },
  {
    name: 'Greece',
    imageB: require(`./country-images/Greece-b.png`).default,
    imageS: require(`./country-images/Greece-s.png`).default,
  },
  {
    name: 'Hong Kong',
    imageB: require(`./country-images/Hong Kong-b.png`).default,
    imageS: require(`./country-images/Hong Kong-s.png`).default,
  },
  {
    name: 'Hungary',
    imageB: require(`./country-images/Hungary-b.png`).default,
    imageS: require(`./country-images/Hungary-s.png`).default,
  },
  {
    name: 'India',
    imageB: require(`./country-images/India-b.png`).default,
    imageS: require(`./country-images/India-s.png`).default,
  },
  {
    name: 'Indonesia',
    imageB: require(`./country-images/Indonesia-b.png`).default,
    imageS: require(`./country-images/Indonesia-s.png`).default,
  },
  {
    name: 'Ireland',
    imageB: require(`./country-images/Ireland-b.png`).default,
    imageS: require(`./country-images/Ireland-s.png`).default,
  },
  {
    name: 'Israel',
    imageB: require(`./country-images/Israel-b.png`).default,
    imageS: require(`./country-images/Israel-s.png`).default,
  },
  {
    name: 'Italy',
    imageB: require(`./country-images/Italy-b.png`).default,
    imageS: require(`./country-images/Italy-s.png`).default,
  },
  {
    name: 'Japan',
    imageB: require(`./country-images/Japan-b.png`).default,
    imageS: require(`./country-images/Japan-s.png`).default,
  },
  {
    name: 'Kazakhstan',
    imageB: ASSETS.THUMB_RANDOM,
    imageS: ASSETS.THUMB_RANDOM,
  },
  {
    name: 'Kenya',
    imageB: require(`./country-images/Kenya-b.png`).default,
    imageS: require(`./country-images/Kenya-s.png`).default,
  },
  {
    name: 'Kuwait',
    imageB: require(`./country-images/Kuwait-b.png`).default,
    imageS: require(`./country-images/Kuwait-s.png`).default,
  },
  {
    name: 'Latvia',
    imageB: require(`./country-images/Latvia-b.png`).default,
    imageS: require(`./country-images/Latvia-s.png`).default,
  },
  {
    name: 'Lebanon',
    imageB: require(`./country-images/Lebanon-b.png`).default,
    imageS: require(`./country-images/Lebanon-s.png`).default,
  },
  {
    name: 'Lithuania',
    imageB: require(`./country-images/Lithuania-b.png`).default,
    imageS: require(`./country-images/Lithuania-s.png`).default,
  },
  {
    name: 'Lithuania',
    imageB: require(`./country-images/Lithuania-b.png`).default,
    imageS: require(`./country-images/Lithuania-s.png`).default,
  },
  {
    name: 'Luxembourg',
    imageB: require(`./country-images/Luxembourg-b.png`).default,
    imageS: require(`./country-images/Luxembourg-s.png`).default,
  },
  {
    name: 'Malaysia',
    imageB: require(`./country-images/Malaysia-b.png`).default,
    imageS: require(`./country-images/Malaysia-s.png`).default,
  },
  {
    name: 'Mexico',
    imageB: require(`./country-images/Mexico-b.png`).default,
    imageS: require(`./country-images/Mexico-s.png`).default,
  },
  {
    name: 'Morocco',
    imageB: require(`./country-images/Morocco-b.png`).default,
    imageS: require(`./country-images/Morocco-s.png`).default,
  },
  {
    name: 'Netherlands',
    imageB: require(`./country-images/Netherlands-b.png`).default,
    imageS: require(`./country-images/Netherlands-s.png`).default,
  },
  {
    name: 'New Zealand',
    imageB: require(`./country-images/New Zealand-b.png`).default,
    imageS: require(`./country-images/New Zealand-s.png`).default,
  },
  {
    name: 'Nigeria',
    imageB: require(`./country-images/Nigeria-b.png`).default,
    imageS: require(`./country-images/Nigeria-s.png`).default,
  },
  {
    name: 'Norway',
    imageB: require(`./country-images/Norway-b.png`).default,
    imageS: require(`./country-images/Norway-s.png`).default,
  },
  {
    name: 'Oman',
    imageB: require(`./country-images/Oman-b.png`).default,
    imageS: require(`./country-images/Oman-s.png`).default,
  },
  {
    name: 'Pakistan',
    imageB: require(`./country-images/Pakistan-b.png`).default,
    imageS: require(`./country-images/Pakistan-s.png`).default,
  },
  {
    name: 'Peru',
    imageB: require(`./country-images/Peru-b.png`).default,
    imageS: require(`./country-images/Peru-s.png`).default,
  },
  {
    name: 'Peru',
    imageB: require(`./country-images/Peru-b.png`).default,
    imageS: require(`./country-images/Peru-s.png`).default,
  },
  {
    name: 'Philippines',
    imageB: require(`./country-images/Philippines-b.png`).default,
    imageS: require(`./country-images/Philippines-s.png`).default,
  },
  {
    name: 'Poland',
    imageB: require(`./country-images/Poland-b.png`).default,
    imageS: require(`./country-images/Poland-s.png`).default,
  },
  {
    name: 'Portugal',
    imageB: require(`./country-images/Portugal-b.png`).default,
    imageS: require(`./country-images/Portugal-s.png`).default,
  },
  {
    name: 'Romania',
    imageB: require(`./country-images/Romania-b.png`).default,
    imageS: require(`./country-images/Romania-s.png`).default,
  },
  {
    name: 'Russia',
    imageB: require(`./country-images/Russia-b.png`).default,
    imageS: require(`./country-images/Russia-s.png`).default,
  },
  {
    name: 'Saudi Arabia',
    imageB: require(`./country-images/Saudi-arabia-b.png`).default,
    imageS: require(`./country-images/Saudi-arabia-s.png`).default,
  },
  {
    name: 'Serbia',
    imageB: require(`./country-images/Serbia-b.png`).default,
    imageS: require(`./country-images/Serbia-s.png`).default,
  },
  {
    name: 'Singapore',
    imageB: require(`./country-images/Singapore-b.png`).default,
    imageS: require(`./country-images/Singapore-s.png`).default,
  },
  {
    name: 'Slovakia',
    imageB: require(`./country-images/slovakia-b.png`).default,
    imageS: require(`./country-images/slovakia-s.png`).default,
  },
  {
    name: 'Slovenia',
    imageB: ASSETS.THUMB_RANDOM,
    imageS: ASSETS.THUMB_RANDOM,
  },
  {
    name: 'South Africa',
    imageB: require(`./country-images/South-Africa-b.png`).default,
    imageS: require(`./country-images/South-Africa-s.png`).default,
  },
  {
    name: 'South Korea',
    imageB: require(`./country-images/South-Korea-b.png`).default,
    imageS: require(`./country-images/South-Korea-s.png`).default,
  },
  {
    name: 'Spain',
    imageB: require(`./country-images/Spain-b.png`).default,
    imageS: require(`./country-images/Spain-s.png`).default,
  },
  {
    name: 'Sweden',
    imageB: require(`./country-images/Sweden-b.png`).default,
    imageS: require(`./country-images/Sweden-s.png`).default,
  },
  {
    name: 'Switzerland',
    imageB: require(`./country-images/Switzerland-b.png`).default,
    imageS: require(`./country-images/Switzerland-s.png`).default,
  },
  {
    name: 'Taiwan',
    imageB: require(`./country-images/Taiwan-b.png`).default,
    imageS: require(`./country-images/Taiwan-s.png`).default,
  },
  {
    name: 'Thailand',
    imageB: require(`./country-images/Thailand-b.png`).default,
    imageS: require(`./country-images/Thailand-s.png`).default,
  },
  {
    name: 'Turkey',
    imageB: require(`./country-images/Turkey-b.png`).default,
    imageS: require(`./country-images/Turkey-s.png`).default,
  },
  {
    name: 'UAE',
    imageB: require(`./country-images/Uae-b.png`).default,
    imageS: require(`./country-images/Uae-s.png`).default,
  },
  {
    name: 'Ukraine',
    imageB: require(`./country-images/Ukraine-b.png`).default,
    imageS: require(`./country-images/Ukraine-s.png`).default,
  },
  {
    name: 'United Kingdom',
    imageB: require(`./country-images/uk-b.png`).default,
    imageS: require(`./country-images/uk-s.png`).default,
  },
  {
    name: 'United States',
    imageB: require(`./country-images/united-states-b.png`).default,
    imageS: require(`./country-images/united-states-s.png`).default,
  },
  {
    name: 'Vietnam',
    imageB: require(`./country-images/Vietnam-b.png`).default,
    imageS: require(`./country-images/Vietnam-s.png`).default,
  },
]